import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Table } from 'react-bootstrap';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';
import { flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import ClientCaseDetailModal from '../Modal/ClientCaseDetailModal';
import UpdateActionDetailStatusModal from '../Modal/UpdateActionDetailStatusModal';
import { useNavigate } from 'react-router-dom';

const AdvanceTable2 = ({
  headerClassName,
  bodyClassName,
  rowClassName,
  tableProps
}) => {
  const table = useAdvanceTableContext();
  const navigate = useNavigate();
  const { getRowModel, getFlatHeaders } = table;

  const [showModal, setShowModal] = useState(false);
  const [selectedClientCode, setSelectedClientCode] = useState('');
  const [selectedClientName, setSelectedClientName] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [actionId, setActionId] = useState('');

  const handleCaseClick = cellData => {
    if (cellData.totalNoOfCase != 0) {
      setSelectedClientCode(cellData.clientCode);
      setSelectedClientName(cellData.clientName);
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  };

  const handleStatusClick = cellData => {
    if (cellData.totalNoOfCase != 0) {
      setActionId(cellData.actionId);
      setShowConfirmationModal(true);
    } else {
      setShowConfirmationModal(false);
    }
  };

  const updateNextDate = cellData => {
    {
      console.log('cellData', cellData);
    }
    localStorage.setItem('CaseScheduleClientCode', cellData.clientCode);
    localStorage.setItem('CaseScheduleCaseNo', cellData.caseNo);
    localStorage.setItem('CaseScheduleClientName', cellData.clientName);
    localStorage.setItem('UpdateCaseNextDate', true);
    // window.open(`/case-next-date/${cellData.caseId}`);
    navigate(`/case-next-date/${cellData.caseId}`);
  };

  return (
    <>
      <div className="table-responsive scrollbar">
        <Table {...tableProps}>
          <thead className={headerClassName}>
            <tr>
              {getFlatHeaders().map(header => {
                return (
                  <th
                    key={header.id}
                    {...header.column.columnDef.meta?.headerProps}
                    className={classNames(
                      'fs-10',
                      header.column.columnDef.meta?.headerProps?.className
                      // {
                      //   sort: header.column.getCanSort(),
                      //   desc: header.column.getIsSorted() === 'desc',
                      //   asc: header.column.getIsSorted() === 'asc'
                      // }
                    )}
                    // onClick={header.column.getToggleSortingHandler()}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className={bodyClassName}>
            {getRowModel().rows.map(row => (
              <tr key={row.id} className={rowClassName}>
                {/* {row.getVisibleCells().map(cell => (
                <td key={cell.id} {...cell.column.columnDef.meta?.cellProps}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))} */}
                {row.getVisibleCells().map((cell, index) => {
                  return (
                    <>
                      <td
                        key={index}
                        {...cell.column.columnDef.meta?.cellProps}
                      >
                        {cell.column.id === 'totalNoOfCase' ? (
                          <Badge
                            pill
                            bg="success"
                            onClick={() => handleCaseClick(cell.row.original)}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Badge>
                        ) : cell.column.id === 'actionItem' ||
                          cell.column.id === 'reminderStatus' ? (
                          parseInt(cell.row.original.reminderDays) <= 2 ||
                          parseInt(cell.row.original.reminderDays) == 0 ? (
                            <>
                              <span
                                style={{ color: 'red' }}
                                onClick={
                                  cell.column.id === 'reminderStatus'
                                    ? () => handleStatusClick(cell.row.original)
                                    : undefined
                                }
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </span>
                            </>
                          ) : parseInt(cell.row.original.reminderDays) > 2 &&
                            parseInt(cell.row.original.reminderDays) <= 4 ? (
                            <span
                              style={{ color: '#FFBE00' }}
                              onClick={
                                cell.column.id === 'reminderStatus'
                                  ? () => handleStatusClick(cell.row.original)
                                  : undefined
                              }
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </span>
                          ) : parseInt(cell.row.original.reminderDays) > 4 ? (
                            <span
                              style={{ color: 'green' }}
                              onClick={
                                cell.column.id === 'reminderStatus'
                                  ? () => handleStatusClick(cell.row.original)
                                  : undefined
                              }
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </span>
                          ) : (
                            <span
                              style={{ color: 'gray' }}
                              onClick={
                                cell.column.id === 'reminderStatus'
                                  ? () => handleStatusClick(cell.row.original)
                                  : undefined
                              }
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </span>
                          )
                        ) : cell.column.id === 'courtName' ? (
                          <span style={{ color: 'blue' }}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </span>
                        ) : cell.column.id === 'action' ? (
                          <span
                            onClick={e => updateNextDate(cell.row.original)}
                          >
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="edit"
                              className="svg-inline--fa fa-edit fa-w-15 edit-icon fa-2x"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 576 512"
                            >
                              <path
                                fill="currentColor"
                                d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"
                              ></path>
                            </svg>
                          </span>
                        ) : cell.column.id === 'status' ? (
                          <Badge
                            pill
                            bg="warning"
                            style={{ backgroundColor: "yellow" }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Badge>
                        ) : (
                          flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )
                        )}
                      </td>
                    </>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </Table>
        <ClientCaseDetailModal
          show={showModal}
          hide={() => setShowModal(false)}
          selectedClientCode={selectedClientCode}
          selectedClientName={selectedClientName}
        />
        <UpdateActionDetailStatusModal
          show={showConfirmationModal}
          onCancel={() => setShowConfirmationModal(false)}
          data={actionId}
        />
      </div>
    </>
  );
};
AdvanceTable2.propTypes = {
  headerClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object
};

export default AdvanceTable2;