import request from 'components/Utils/Request';
import axios from 'axios';
const { REACT_APP_API_URL } = process.env;
export const GET_CASE_SCHEDULE_LIST_REQUEST = 'GET_CASE_SCHEDULE_LIST_REQUEST';
export const GET_CASE_SCHEDULE_LIST_SUCCESS = 'GET_CASE_SCHEDULE_LIST_SUCCESS';
export const GET_CASE_SCHEDULE_LIST_FAILURE = 'GET_CASE_SCHEDULE_LIST_FAILURE';
export const GENERATE_CASE_SCHEDULE_PDF_REQUEST = 'GENERATE_CASE_SCHEDULE_PDF_REQUEST';
export const GENERATE_CASE_SCHEDULE_PDF_SUCCESS = 'GENERATE_CASE_SCHEDULE_PDF_SUCCESS';
export const GENERATE_CASE_SCHEDULE_PDF_FAILURE = 'GENERATE_CASE_SCHEDULE_PDF_FAILURE';
export const NEXT_DATE_DETAIL_PDF_REQUEST= 'NEXT_DATE_DETAIL_PDF_REQUEST';
export const NEXT_DATE_DETAIL_PDF_SUCCESS = 'NEXT_DATE_DETAIL_PDF_SUCCESS';
export const NEXT_DATE_DETAIL_PDF_FAILURE = 'NEXT_DATE_DETAIL_PDF_FAILURE';

export const getCaseSchedulelistRequest = () => ({
    type: GET_CASE_SCHEDULE_LIST_REQUEST
  });
  export const getCaseSchedulelistSuccess = payload => ({
    type: GET_CASE_SCHEDULE_LIST_SUCCESS,
    payload
  });
  export const getCaseSchedulelistFailure = payload => ({
    type: GET_CASE_SCHEDULE_LIST_FAILURE,
    payload
  });

  export const generateCaseSchedulePDFRequest = () => ({
    type: GENERATE_CASE_SCHEDULE_PDF_REQUEST
  });
  export const generateCaseSchedulePDFSuccess = payload => ({
    type: GENERATE_CASE_SCHEDULE_PDF_SUCCESS,
    payload
  });
  export const generateCaseSchedulePDFFailure = payload => ({
    type: GENERATE_CASE_SCHEDULE_PDF_FAILURE,
    payload
  });

  export const getNextDateDetailPDFRequest = () => ({
    type: NEXT_DATE_DETAIL_PDF_REQUEST
  });
  export const getNextDateDetailPDFSuccess = payload => ({
    type: NEXT_DATE_DETAIL_PDF_SUCCESS,
    payload
  });
  export const getNextDateDetailPDFFailure = payload => ({
    type: NEXT_DATE_DETAIL_PDF_FAILURE,
    payload
  });

  export const caseScheduleAction = payload => {
    return {
      type: 'CASESCHEDULEDATA',
      payload
    };
  };

  export const getCaseScheduleListAPI = (payload, successData, errorMSg, headers = {}) => {
    return dispatch => {
      dispatch(getCaseSchedulelistRequest());
      return request(
        'POST',
        `${REACT_APP_API_URL + '/get-client-case-schedule-list'}`,
        payload,
        "",
        headers
      )
        .then(res => {
          if (res?.data.status === 200) {
            successData(res.data);
            dispatch(getCaseSchedulelistSuccess(res.data));
          } else {
            errorMSg(res);
          }
        })
        .catch(err => {
          errorMSg(err?.res);
          dispatch(getCaseSchedulelistFailure('Something went wrong'));
          throw err;
        });
    };
  };

export const generateCaseSchedulePdfAPI = (payload, successCallback, errorCallback, headers = {}) => {
  return dispatch => {
    dispatch(generateCaseSchedulePDFRequest());
    axios
      .post(
        `${REACT_APP_API_URL}/get-client-case-schedule-list`,
        payload,
        {
          responseType: 'blob',
          headers: headers,
        }
      )
      .then(response => {
        if (response.status === 200 && response.data) {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const downloadUrl = URL.createObjectURL(blob);
          window.open(downloadUrl, '_blank');
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', 'CaseSchedule.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          dispatch(generateCaseSchedulePDFSuccess());
        } else {
          errorCallback("Failed to generate PDF");
          dispatch(generateCaseSchedulePDFFailure());
        }
      })
      .catch(error => {
        console.error("Error downloading PDF:", error);
        errorCallback("Error occurred while downloading PDF");
        dispatch(generateCaseSchedulePDFFailure());
      });
  };
};

  export const nextDatePdfAPI = (payload, headers, successCallback) => {
  return dispatch => {
    dispatch(getNextDateDetailPDFRequest());
    axios.post(
      `${REACT_APP_API_URL}/get-next-date-detail`,
      payload,
      {
        responseType: 'blob', 
        headers: headers, 
      }
    )
    .then(response => {
      if (response.headers['content-type'] === 'application/pdf') {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const downloadUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'NextDateDetail.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        dispatch(getNextDateDetailPDFSuccess());
      } else {
        successCallback(response);
        dispatch(getNextDateDetailPDFFailure('Data not found'));
      }
    })
    .catch(error => {
      console.error("Error downloading PDF:", error);
      dispatch(getNextDateDetailPDFFailure());
    });
  };
};