import React from 'react';
import { useEffect, useState } from 'react';
import { Col, Form, Row, Button, InputGroup, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { advocateDetailsAction, formChangedAction } from '../../actions/index';
import EnlargableTextbox from 'components/common/EnlargableTextbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { countryListAPI } from 'actions/CountryMaster/countryMasterAction';
import { toast } from 'react-toastify';
import { stateListAPI } from 'actions/StateMaster/stateMasterAction';
import {
  advocateEmailMobileValidationAPI,
  verifyEmailMobileNoAPI,
  viewAdvocateDetailAPI
} from 'actions/AdvocateRegistration/advocateRegistrationAction';
import ConfirmationModal from 'components/common/Modal/ConfirmationModal';
import DeleteImageConfirmationModal from 'components/common/Modal/DeleteImageConfirmationModal';

export const ProfileDetail = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [currentCheck, setCurrentCheck] = useState('');
  const [showModal, setShowModal] = useState('');
  const [emailMobileExistsMessage, setEmailMobileExistsMessage] = useState('');

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  useEffect(() => {
    viewAdvocateProfileDetail();
    getCountriesList();
  }, []);

  const advocateDetailsReducer = useSelector(
    state => state.rootReducer.advocateDetailsReducer
  );
  var advocateData = advocateDetailsReducer.advocateDetails;

  const advocateDetailsErrorReducer = useSelector(
    state => state.rootReducer.advocateDetailsErrorReducer
  );
  const advocateError = advocateDetailsErrorReducer.advocateDetailsError;

  const formChangedReducer = useSelector(
    state => state.rootReducer.formChangedReducer
  );
  var formChangedData = formChangedReducer.formChanged;

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [formHasError, setFormError] = useState(false);
  const [perPage, setPerPage] = useState(15);
  const [oldEmail, setOldEmail] = useState('');
  const [oldMobileNo, setOldMobileNo] = useState('');
  const [isEmailChanged, setIsEmailChanged] = useState(false);
  const [isMobileNoChanged, setIsMobileNoChanged] = useState(false);
  const [emailErr, setEmailErr] = useState('');
  const [mobileNoErr, setMobileNoErr] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    if (advocateData && advocateData.stateCode && !$('#txtState').val()) {
      getStatesList(advocateData.countryCode);
    }
  }, [advocateData]);

  const getCountriesList = async (page, size = perPage) => {
    const data = {
      pageNumber: page,
      pageSize: size,
      SearchText: '',
      isDropDown: true
    };
    await dispatch(
      countryListAPI(
        data,
        res => {
          let countryData = [];
          if (res.data.data.countryList.length > 0)
            res.data.data.countryList.forEach(country => {
              countryData.push({
                key: country.countryName,
                value: country.countryCode
              });
            });
          setCountryList(countryData);
        },
        err => {
          toast.error(err?.data?.message || 'Error fetching countries', {
            theme: 'colored',
            autoClose: 10000
          });
          setCountryList([]);
        },
        headers
      )
    );
  };

  const getStatesList = async (selectCountryCode) => {
    const data = {
      countryCode: selectCountryCode,
      isDropDown: true,
      isList: false
    };
    await dispatch(
      stateListAPI(
        data,
        res => {
          let stateData = [];
          if (res && res.length > 0) {
            res.forEach(state => {
              stateData.push({
                key: state.stateName,
                value: state.stateCode
              });
            });
            setStateList(stateData);
          } else {
            setStateList([]);
          }
        },
        err => {
          setStateList([]);
        }
      )
    );
  };

  const handleFieldChange = e => {
    dispatch(
      advocateDetailsAction({
        ...advocateData,
        [e.target.name]: e.target.value
      })
    );

    if (e.target.name == 'countryCode') {
      if (e.target.value == '') setStateList([]);
      else getStatesList(e.target.value);
    }
    if (e.target.name === 'email') {
      setIsEmailChanged(
        e.target.value.toLowerCase() !== oldEmail.toLowerCase()
      );
    }
    if (e.target.name === 'mobileNo') {
      setIsMobileNoChanged(e.target.value !== oldMobileNo);
    }
   
    if (e.target.name == 'advocatePicFile') {
      if (e.target.files[0]) {
        dispatch(
          advocateDetailsAction({
            ...advocateData,
            advocatePicFile: e.target.files[0],
            advocatePicFileURL: URL.createObjectURL(e.target.files[0])
          })
        );
      } else {
        dispatch(
          advocateDetailsAction({
            ...advocateData,
            advocatePicFile: null,
            advocatePicFileURL: ''
          })
        );
      }
    }
    if (advocateData.encryptedAdvocateCode) {
      dispatch(
        formChangedAction({
          ...formChangedData,
          advocateUpdate: true
        })
      );
    }
  };

  const removeUploadFile = () => {
    $('#advocatePicFile').val(null);
    dispatch(
      advocateDetailsAction({
        ...advocateData,
        advocatePicFile: '',
        advocatePicFileURL: '',
        IsDeleteAdvocatePicFile:true
      })
    );
    dispatch(
      formChangedAction({
        ...formChangedData,
        advocateUpdate: true
      })
    );
    $('#btnSave').attr('disabled', false);
    setDeleteModal(false);
  };

  const viewAdvocateProfileDetail = async () => {
    const requestData = {
      EncryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode')
    };
    await dispatch(
      viewAdvocateDetailAPI(
        requestData,
        res => {
          setIsLoading(false);
          dispatch(advocateDetailsAction(res.data.data));
          setOldEmail(res.data.data.email);
          setOldMobileNo(res.data.data.mobileNo);
        },
        err => {
          setIsLoading(false);
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  const checkMobileNo = async () => {
    if (EmailOrMobileNoValidation()) {
      const requestData = {
        email: '',
        phoneNumber: advocateData.mobileNo,
        encryptedAdvocateCode: advocateData.encryptedAdvocateCode
          ? advocateData.encryptedAdvocateCode
          : '',
        isUpdate: advocateData.encryptedAdvocateCode ? true : false
      };
      setIsLoading(true);
      dispatch(
        advocateEmailMobileValidationAPI(
          requestData,
          response => {
            console.log('api call success for mobile');
            setIsLoading(false);
            const message = response?.data?.message || '';
            if (response?.data?.status === 200) {
              setShowModal(true);
            } else {
              verifyEmailMobileNo(advocateData.mobileNo);
            }
            setEmailMobileExistsMessage(message);
          },
          err => {
            setIsLoading(false);
            console.log(err?.data?.message);
          },
          headers
        )
      );
    }
  };

  const checkEmailId = async () => {
    const requestData = {
      email: advocateData.email,
      phoneNumber: '',
      encryptedAdvocateCode: advocateData.encryptedAdvocateCode
        ? advocateData.encryptedAdvocateCode
        : '',
      isUpdate: advocateData.encryptedAdvocateCode ? true : false
    };
    setIsLoading(true);
    if (EmailOrMobileNoValidation()) {
      await dispatch(
        advocateEmailMobileValidationAPI(
          requestData,
          response => {
            console.log('api call success for email');
            setIsLoading(false);
            const message = response?.data?.message || '';
            if (response?.data?.status === 200) {
              setShowModal(true);
            } else {
              verifyEmailMobileNo(advocateData.email);
            }
            setEmailMobileExistsMessage(message);
          },
          err => {
            setIsLoading(false);
            console.log(err?.data.message);
          },
          headers
        )
      );
    }
  };

  const confirmEmailOrMobileNo = async () => {
    setShowModal(false);
    if (currentCheck === 'email') {
      setCurrentCheck('');
      await verifyEmailMobileNo(advocateData.email);
    } else if (currentCheck === 'mobileNo') {
      setCurrentCheck('');
      await verifyEmailMobileNo(advocateData.mobileNo);
    }
  };

  const verifyEmailMobileNo = async (emailOrMobileNo) => {
    setIsLoading(true);
    const requestData = {
      EncryptedAdvocateCode: advocateData.encryptedAdvocateCode,
      EmailOrMobileNo: emailOrMobileNo
    };
    await dispatch(
      verifyEmailMobileNoAPI(
        requestData,
        response => {
          setIsLoading(false);
          toast.success(`${response.data.message}`, {
            theme: 'colored',
            autoClose: 10000
          });
        },
        err => {
          setIsLoading(false);
          setFormError(true);
          console.log('Error for API:', err?.data.message);
        },
        headers
      )
    );
  };

  const EmailOrMobileNoValidation = () => {
    setIsLoading(false);
    const emailErr = {};
    const mobileNoErr = {};

    let isValid = true;

    if (!advocateData.mobileNo) {
      mobileNoErr.mobileNoErr = 'Enter mobile no';
      isValid = false;
    } else if (!/^(?!0)[0-9]{10}$/.test(advocateData.mobileNo)) {
      mobileNoErr.phoneInvalid =
        'Invalid mobile number, number should be of 10 digits should not start with 0';
      isValid = false;
    }

    if (!advocateData.email) {
      emailErr.empty = 'Enter email address';
      isValid = false;
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(advocateData.email)
    ) {
      emailErr.invalid = 'Please enter valid email address';
      isValid = false;
      setFormError(true);
    }

    setMobileNoErr(mobileNoErr);
    setEmailErr(emailErr);
    return isValid;
  };

  return (
    <>
      {isLoading ? (
        <Spinner
          className="position-absolute start-50 loader-color"
          animation="border"
        />
      ) : null}

      {advocateData && (
        <Form
          noValidate
          validated={formHasError}
          className="details-form advocateProfile"
          id="addAdvocateDetailsForm"
        >
          <Row className="g-0">
            <Col className="me-3 ms-3" md="3" sm="6" xs="12"  >
              <Row className="mb-3">
                <Form.Label>
                  Advocate Name<span className="text-danger">*</span>
                </Form.Label>
                <EnlargableTextbox
                  id="txtAdvocateName"
                  name="advocateName"
                  maxLength={60}
                  value={advocateData.advocateName}
                  onChange={handleFieldChange}
                  placeholder="Advocate Name"
                  required={true}
                  disabled
                />
                {Object.keys(advocateError.advocateNameErr).map(key => {
                  return (
                    <span className="error-message">
                      {advocateError.advocateNameErr[key]}
                    </span>
                  );
                })}
              </Row>
              <Row className="mb-3">
                <Form.Label>
                  Chamber No.<span className="text-danger">*</span>
                </Form.Label>
                <EnlargableTextbox
                  id="txtChamberNo"
                  name="chamberNo"
                  maxLength={50}
                  value={advocateData.chamberNo}
                  onChange={handleFieldChange}
                  placeholder="Chamber No."
                />
                {Object.keys(advocateError.chamberNoErr).map(key => {
                  return (
                    <span className="error-message">
                      {advocateError.chamberNoErr[key]}
                    </span>
                  );
                })}
              </Row>
              <Row className="mb-3">
                <Form.Label>Practice Since</Form.Label>
                <EnlargableTextbox
                  id="txtPracticeSince"
                  name="practiceSince"
                  maxLength={4}
                  value={advocateData.practiceSince}
                  onChange={handleFieldChange}
                  onKeyPress={e => {
                    const regex = /[0-9]|\./;
                    const key = String.fromCharCode(e.charCode);
                    if (!regex.test(key)) {
                      e.preventDefault();
                    }
                  }}
                  placeholder="Practice Since"
                />
                {Object.keys(advocateError.practiceSinceErr).map(key => {
                  return (
                    <span className="error-message">
                      {advocateError.practiceSinceErr[key]}
                    </span>
                  );
                })}
              </Row>
              <Row className="mb-3">
                <Form.Label>Contact Person</Form.Label>
                <EnlargableTextbox
                  id="contactPerson"
                  maxLength={50}
                  value={advocateData.contactPerson}
                  name="contactPerson"
                  onChange={handleFieldChange}
                  placeholder="Contact Person"
                />
              </Row>
              <Row className="mb-3">
                <Form.Label>
                  Country<span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  id="txtCountry"
                  name="countryCode"
                  value={advocateData.countryCode}
                  onChange={handleFieldChange}
                  required
                >
                  <option value="">Select country</option>
                  {countryList.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.key}
                    </option>
                  ))}
                </Form.Select>
                {Object.keys(advocateError.countryErr).map(key => {
                  return (
                    <span className="error-message">
                      {advocateError.countryErr[key]}
                    </span>
                  );
                })}
              </Row>
              <Row className="mb-3">
                <Form.Label>
                  State<span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  id="txtState"
                  name="stateCode"
                  value={advocateData.stateCode}
                  onChange={handleFieldChange}
                  required
                >
                  <option value="">Select state</option>
                  {stateList.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.key}
                    </option>
                  ))}
                </Form.Select>
                {Object.keys(advocateError.stateErr).map(key => {
                  return (
                    <span className="error-message">
                      {advocateError.stateErr[key]}
                    </span>
                  );
                })}
              </Row>
              <Row className="mb-3">
                <Form.Label>
                  Email ID<span className="text-danger">*</span>
                </Form.Label>
                <Col className="d-flex g-0">
                  <EnlargableTextbox
                    id="txtEmail"
                    name="email"
                    maxLength={100}
                    value={advocateData.email}
                    onChange={handleFieldChange}
                    placeholder="Email ID"
                    required
                  />
                  <Button
                    className="btn-sm btn-primary"
                    onClick={() => {
                      checkEmailId();
                      setCurrentCheck('email');
                    }}
                    disabled={!isEmailChanged || !advocateData.email}
                  >
                    Verify
                  </Button>
                </Col>
                {Object.keys(emailErr).map(key => {
                  return <span className="error-message">{emailErr[key]}</span>;
                })}
              </Row>
              <Row className="">
                <Form.Label>
                  Mobile No.<span className="text-danger">*</span>
                </Form.Label>
                <Col className="d-flex g-0">
                  <EnlargableTextbox
                    id="txtMobileNo"
                    name="mobileNo"
                    maxLength={10}
                    value={advocateData.mobileNo}
                    onChange={handleFieldChange}
                    placeholder="Mobile No."
                    onKeyPress={e => {
                      const regex = /[0-9]|\./;
                      const key = String.fromCharCode(e.charCode);
                      if (!regex.test(key)) {
                        e.preventDefault();
                      }
                    }}
                    required
                  />
                  <Button
                    className="btn-sm btn-primary"
                    onClick={() => {
                      checkMobileNo();
                      setCurrentCheck('mobileNo');
                    }}
                    disabled={!isMobileNoChanged || !advocateData.mobileNo}
                  >
                    Verify
                  </Button>
                </Col>
                {Object.keys(mobileNoErr).map(key => {
                  return (
                    <span className="error-message">{mobileNoErr[key]}</span>
                  );
                })}
              </Row>
  
            </Col>
            <Col className="ms-5 me-3" md="3" sm="6" xs="12">
             
              <Row className="mb-3">
                <Form.Label>Landline No.</Form.Label>
                <EnlargableTextbox
                  id="txtlandlineNo"
                  name="landlineNo"
                  maxLength={40}
                  value={advocateData.landlineNo}
                  onChange={handleFieldChange}
                  placeholder="LandLine No."
                  onKeyPress={e => {
                    const regex = /[0-9]|\./;
                    const key = String.fromCharCode(e.charCode);
                    if (!regex.test(key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Row>
              <Row className="mb-3">
                <Form.Label>Advocate Address</Form.Label>
                <EnlargableTextbox
                  id="txtAdvocateAddress"
                  name="advocateAddress1"
                  maxLength={200}
                  value={advocateData.advocateAddress1}
                  onChange={handleFieldChange}
                  className="mb-1"
                  placeholder="Address"
                />
                <EnlargableTextbox
                  id="txtAdvocateAddress2"
                  name="advocateAddress2"
                  maxLength={200}
                  value={advocateData.advocateAddress2}
                  onChange={handleFieldChange}
                  className="mb-1"
                  placeholder="Address 2"
                />
                <EnlargableTextbox
                  id="txtAdvocateAddress3"
                  name="advocateAddress3"
                  maxLength={200}
                  value={advocateData.advocateAddress3}
                  onChange={handleFieldChange}
                  className="mb-1"
                  placeholder="Address 3"
                />
              </Row>
              <Row className="mb-3">
                <Form.Label>Client Profile</Form.Label>
                <EnlargableTextbox
                  id="txtClientProfile"
                  name="clientProfile"
                  maxLength={500}
                  value={advocateData.clientProfile}
                  onChange={handleFieldChange}
                  className="mb-1"
                  placeholder="Client Profile"
                />
              </Row>
              <Row className="mb-3">
                <Form.Label>GST No.</Form.Label>
                <EnlargableTextbox
                  id="txtGSTNo"
                  name="gstNo"
                  maxLength={15}
                  value={advocateData.gstNo}
                  onChange={handleFieldChange}
                  placeholder="GST No."
                  disabled
                />
              </Row>
            </Col>
            <Col className="me-3 ms-5" md="3" sm="6" xs="12">
              <Row className="mb-3">
                <Form.Label>Advocate Profile</Form.Label>
                {advocateData && advocateData.advocatePicFileURL ? (
                  <>
                    <img
                      src={advocateData.advocatePicFileURL}
                      id="advocatePicUploadFile"
                      className="img-thumbnail shadow-sm logo-photo p3px"
                    />
                    <InputGroup className="mb-1 no-padding mt-1">
                      <Button
                        onClick={() => {
                          document.getElementById('advocatePicFile').click();
                        }}
                      >
                        Change
                      </Button>
                      <InputGroup.Text>
                        <FontAwesomeIcon
                          icon={'trash'}
                          onClick={() => {
                            // removeUploadFile();
                            setDeleteModal(true);
                          }}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      document.getElementById('advocatePicFile').click();
                    }}
                  >
                    Upload
                  </Button>
                )}
                {Object.keys(advocateError.advocatePicFileErr).map(key => {
                  return (
                    <span className="error-message">
                      {advocateError.advocatePicFileErr[key]}
                    </span>
                  );
                })}
                <Form.Control
                  type="file"
                  id="advocatePicFile"
                  name="advocatePicFile"
                  onChange={handleFieldChange}
                  hidden
                />
              </Row>
            </Col>
          </Row>
        </Form>
      )}
      <ConfirmationModal
        show={showModal}
        onConfirm={confirmEmailOrMobileNo}
        onCancel={() => setShowModal(false)}
        message={emailMobileExistsMessage}
      />
      <DeleteImageConfirmationModal
       show={deleteModal}
       onConfirm={removeUploadFile}
       onCancel={() => setDeleteModal(false)}
      />
    </>
  );
};

export default ProfileDetail;