import React, { useEffect, useState } from 'react';
import TabPage from 'components/common/TabPage';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { formChangedAction } from 'actions';
import { clientListAPI } from 'actions/ClientRegistration/ClientRegistrationAction.js';
import {
  filePlacementDataAction,
  getFilePlacementListAPI,
  updateFilePlacementAPI
} from 'actions/AdvocateClientCase/FilePlacement/filePlacementAction';
import { getClientCaseMasterListAPI } from 'actions/CaseNextDate/caseNextDateAction';
import TabPage1 from 'components/common/TabPage1';

const tabArray = ['File Placement List', 'File Placement Details'];

const listColumnArray = [
  { accessor: 'sl', Header: 'S. No' },
  { accessor: 'clientName', Header: 'Client Name' },
  { accessor: 'clientCaseNo', Header: 'Case No' },
  { accessor: 'numbers', Header: 'File No/ Room No/ Almirah No/ Rack No',
    Cell: ({ value }) => (
      <div className="truncate-text" title={value}>
        {value}
      </div>
    )
   },
  {
    accessor: 'fileDescription',
    Header: 'File Description',
    Cell: ({ value }) => (
      <div className="truncate-text" title={value}>
        {value}
      </div>
    )
  }
];

export const FilePlacement = () => {
  const dispatch = useDispatch();
  const [filePlacementList, setFilePlacementList] = useState([]);
  // const [perPage, setPerPage] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [clientCaseMasterList, setClientCaseMasterList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [searchText, setSearchText] = useState('');
  const selectedClientCode = localStorage.getItem('ClientCode');
  const selectedClientCaseNo = localStorage.getItem('CaseNo');

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  const getFilePlacementList = async (
    page,
    size = pageSize,
    searchText,
    clientCode = '',
    caseNo = ''
  ) => {
    const data = {
      pageNumber: page,
      pageSize: size,
      searchText: searchText ? searchText : '',
      EncryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode'),
      ClientCode: clientCode ? clientCode : localStorage.getItem('ClientCode'),
      CaseNo: caseNo ? caseNo : selectedClientCaseNo
    };
    setIsLoading(true);
    await dispatch(
      getFilePlacementListAPI(
        data,
        response => {
          setIsLoading(false);
          setFilePlacementList(response.data.data.filePlacementList);
          setTotalCount(response.data.data.totalCount);
        },
        err => {
          setIsLoading(false);
          setFilePlacementList([]);
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  const getClientList = async (page, size = pageSize) => {
    const data = {
      pageNumber: page,
      pageSize: size,
      SearchText: '',
      EncryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode'),
      isDropDown: true
    };
    setIsLoading(true);
    await dispatch(
      clientListAPI(
        data,
        res => {
          setIsLoading(false);
          let clientData = [];

          if (res.data.data.clientList.length > 0)
            res.data.data.clientList.forEach(client => {
              clientData.push({
                key: client.clientName,
                value: client.clientCode,
                label: client.clientName
              });
            });
          setClientList(clientData);
          if (
            res.data.data.clientList &&
            res.data.data.clientList.length === 1
          ) {
            getClientCaseMasterList(res.data.data.clientList[0].clientCode);
            localStorage.setItem(
              'ClientCode',
              res.data.data.clientList[0].clientCode
            );
          }
        },
        err => {
          setClientList([]);
          setIsLoading(false);
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  const getClientCaseMasterList = async clientCode => {
    const data = {
      EncryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode'),
      ClientCode: clientCode ? clientCode : selectedClientCode
    };
    dispatch(
      await getClientCaseMasterListAPI(
        data,
        res => {
          let caseMasterData = [];
          if (res.data && res.data.data.caseMasterList.length > 0) {
            res.data.data.caseMasterList.forEach(data => {
              caseMasterData.push({
                key: data.caseNo,
                value: data.caseNo
              });
            });
            setClientCaseMasterList(caseMasterData);
          } else {
            setClientCaseMasterList([]);
          }
        },
        err => {
          setClientCaseMasterList([]);
        },
        headers
      )
    );
  };

  // useEffect(() => {
  //   $('#btnNew').hide();
  //   getClientList(1)
  //   localStorage.removeItem("ClientCode")
  //   localStorage.removeItem("CaseNo")
  //   localStorage.removeItem("ClientName")
  //   localStorage.removeItem("EncryptedResponseCaseId")
  // }, []);

  useEffect(() => {
    $('#btnNew').hide();
    getClientList(1);
    getFilePlacementList(1, pageSize);
  }, []);

  const filePlacementReducer = useSelector(
    state => state.rootReducer.filePlacementReducer
  );
  var filePlacementData = filePlacementReducer.filePlacementData;

  const formChangedReducer = useSelector(
    state => state.rootReducer.formChangedReducer
  );
  var formChangedData = formChangedReducer.formChanged;

  let isFormChanged = Object.values(formChangedData).some(
    value => value === true
  );

  const [activeTabName, setActiveTabName] = useState();

  const clearFilePlacementReducers = () => {
    dispatch(filePlacementDataAction(undefined));
    dispatch(formChangedAction(undefined));
  };

  $('[data-rr-ui-event-key*="File Placement List"]')
    .off('click')
    .on('click', function () {
      let isDiscard = $('#btnDiscard').attr('isDiscard');
      if (isDiscard != 'true' && isFormChanged) {
        setModalShow(true);
        setTimeout(function () {
          $('[data-rr-ui-event-key*="' + activeTabName + '"]').trigger('click');
        }, 50);
      } else {
        $('#btnSave').hide();
        $('#btnCancel').hide();
        $('[data-rr-ui-event-key*="Details"]').attr('disabled', true);
        $('[data-rr-ui-event-key*="File Placement List"]').attr(
          'disabled',
          false
        );
        $('#UpdateFilePlacementDetailsForm').get(0).reset();

        if (selectedClientCode && selectedClientCaseNo) {
          getFilePlacementList(
            1,
            pageSize,
            '',
            selectedClientCode,
            selectedClientCaseNo
          );
        } else if (selectedClientCode) {
          getFilePlacementList(1, pageSize, '', selectedClientCode);
        } else {
          getFilePlacementList(1, pageSize);
        }

        localStorage.removeItem('EncryptedResponseClientCode');
        localStorage.removeItem('EncryptedResponseCaseId');

        $('#btnDiscard').attr('isDiscard', false);
        clearFilePlacementReducers();
      }
    });

  $('[data-rr-ui-event-key*="File Placement Details"]')
    .off('click')
    .on('click', function () {
      setActiveTabName('File Placement Details');
      $('#btnSave').show();
      $('#btnCancel').show();
    });

  const newDetails = () => {
    $('[data-rr-ui-event-key*="Details"]').attr('disabled', false);
    $('[data-rr-ui-event-key="File Placement Details"]').trigger('click');
    $('#btnSave').attr('disabled', false);
    clearFilePlacementReducers();
  };

  const cancelClick = () => {
    $('#btnExit').attr('isExit', 'false');
    if (isFormChanged) {
      setModalShow(true);
    } else {
      $('[data-rr-ui-event-key*="File Placement List"]').trigger('click');
    }
  };

  const exitModule = () => {
    $('#btnExit').attr('isExit', 'true');
    if (isFormChanged) {
      setModalShow(true);
    } else {
      window.location.href = '/dashboard';
    }
  };

  const discardChanges = () => {
    localStorage.removeItem('ClientCode');
    localStorage.removeItem('EncryptedResponseCaseId');
    $('#btnDiscard').attr('isDiscard', 'true');
    if ($('#btnExit').attr('isExit') == 'true')
      window.location.href = '/dashboard';
    else $('[data-rr-ui-event-key*="List"]').trigger('click');
    setModalShow(false);
  };

  const updateCallback = () => {
    setModalShow(false);
    getFilePlacementList(
      1,
      pageSize,
      '',
      selectedClientCode,
      selectedClientCaseNo
    );
    dispatch(formChangedAction(undefined));
    $('#btnSave').attr('disabled', true);
    $('[data-rr-ui-event-key*="' + activeTabName + '"]').trigger('click');
  };

  const updateFilePlacementDetails = async () => {
    const request = {
      EncryptedCaseId: filePlacementData.encryptedCaseId,
      RoomNo: filePlacementData.roomNo ? filePlacementData.roomNo : '',
      Almirah: filePlacementData.almirah ? filePlacementData.almirah : '',
      FileDescription: filePlacementData.fileDescription
        ? filePlacementData.fileDescription
        : '',
      Rack: filePlacementData.rack ? filePlacementData.rack : '',
      FileNo: filePlacementData.fileNo ? filePlacementData.fileNo : '',
      ModifyUser: localStorage.getItem('LoginUserName')
    };

    const keys = [
      'FileDescription',
      'FileNo',
      'Rack',
      'Almirah',
      'RoomNo',
      'ModifyUser'
    ];
    for (const key of Object.keys(request).filter(key => keys.includes(key))) {
      request[key] = request[key] ? request[key].toUpperCase() : '';
    }

    setIsLoading(true);
    if (
      filePlacementData.fileNo != '' ||
      filePlacementData.roomNo != '' ||
      filePlacementData.almirah != '' ||
      filePlacementData.fileDescription != '' ||
      filePlacementData.rack != ''
    ) {
      if (filePlacementData.encryptedCaseId) {
        await dispatch(
          updateFilePlacementAPI(
            request,
            res => {
              toast.success(res?.data.message, {
                theme: 'colored',
                autoClose: 10000
              });
              updateCallback();
              setIsLoading(false);
              setModalShow(false);
            },
            err => {
              setIsLoading(false);
              toast.error(err?.data.message, {
                theme: 'colored',
                autoClose: 10000
              });
            },
            headers
          )
        );
      }
    } else {
      setIsLoading(false);
    }
  };

  const handleFieldChange = e => {
    if (e.target.value) {
      setClientCaseMasterList([]);
      localStorage.setItem('ClientCode', e.target.value);
      localStorage.removeItem('CaseNo');
      getClientCaseMasterList(e.target.value);
      getFilePlacementList(1, pageSize, '', e.target.value);
    } else {
      localStorage.removeItem('ClientCode');
      localStorage.removeItem('CaseNo');
      getFilePlacementList(1, pageSize);
      setClientCaseMasterList([]);
    }
  };

  const handleFieldChange1 = e => {
    if (e.target.value) {
      getFilePlacementList(1, pageSize, '', selectedClientCode, e.target.value);
      localStorage.setItem('CaseNo', e.target.value);
    } else {
      getFilePlacementList(1, pageSize, '', selectedClientCode);
      localStorage.removeItem('CaseNo');
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner
          className="position-absolute start-50 loader-color"
          animation="border"
        />
      ) : null}

      {modalShow && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Do you want to save changes?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={updateFilePlacementDetails}>
              Save
            </Button>
            <Button
              variant="danger"
              id="btnDiscard"
              onClick={() => discardChanges()}
            >
              Discard
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <TabPage1
        listData={filePlacementList}
        listColumnArray={listColumnArray}
        tabArray={tabArray}
        module="FilePlacement"
        saveDetails={updateFilePlacementDetails}
        newDetails={newDetails}
        cancelClick={cancelClick}
        exitModule={exitModule}
        tableFilterOptions={clientList}
        tableFilterName={'Client'}
        supportingMethod1={handleFieldChange}
        tableFilterOptions1={clientCaseMasterList}
        tableFilterName1={'Case No'}
        supportingMethod2={handleFieldChange1}
        pageSize={pageSize}
        setPageSize={value => setPageSize(value)}
        searchText={searchText}
        setSearchTextValue={value => setSearchText(value)}
        fetchList={getFilePlacementList}
        listCount={totalCount}
      />
    </>
  );
};

export default FilePlacement;
