import request from 'components/Utils/Request';
const { REACT_APP_API_URL } = process.env;
export const ADD_ACTION_DETAILS_REQUEST = 'ADD_ACTION_DETAILS_REQUEST';
export const ADD_ACTION_DETAILS_SUCCESS = 'ADD_ACTION_DETAILS_SUCCESS';
export const ADD_ACTION_DETAILS_FAILURE = 'ADD_ACTION_DETAILS_FAILURE';
export const UPDATE_ACTION_DETAILS_REQUEST = 'UPDATE_ACTION_DETAILS_REQUEST';
export const UPDATE_ACTION_DETAILS_SUCCESS = 'UPDATE_ACTION_DETAILS_SUCCESS';
export const UPDATE_ACTION_DETAILS_FAILURE = 'UPDATE_ACTION_DETAILS_FAILURE';
export const GET_ACTION_DETAILS_LIST_REQUEST = 'GET_ACTION_DETAILS_LIST_REQUEST';
export const GET_ACTION_DETAILS_LIST_SUCCESS = 'GET_ACTION_DETAILS_LIST_SUCCESS';
export const GET_ACTION_DETAILS_LIST_FAILURE = 'GET_ACTION_DETAILS_LIST_FAILURE';
export const UPDATE_ACTION_DETAIL_STATUS_REQUEST = 'UPDATE_ACTION_DETAIL_STATUS_REQUEST';
export const UPDATE_ACTION_DETAIL_STATUS_SUCCESS = 'UPDATE_ACTION_DETAIL_STATUS_SUCCESS';
export const UPDATE_ACTION_DETAIL_STATUS_FAILURE = 'UPDATE_ACTION_DETAIL_STATUS_FAILURE';
export const actionDetailsRequest = () => ({
    type: ADD_ACTION_DETAILS_REQUEST
  });
  export const actionDetailsSuccess = payload => ({
    type: ADD_ACTION_DETAILS_SUCCESS,
    payload
  });
  export const actionDetailsFailure = payload => ({
    type: ADD_ACTION_DETAILS_FAILURE,
    payload
  });

  export const updateActionDetailsRequest = () => ({
    type: UPDATE_ACTION_DETAILS_REQUEST
  });
  export const updateActionDetailsSuccess = payload => ({
    type: UPDATE_ACTION_DETAILS_SUCCESS,
    payload
  });
  export const updateActionDetailsFailure = payload => ({
    type: UPDATE_ACTION_DETAILS_FAILURE,
    payload
  });

  export const getActionDetailsListRequest = () => ({
    type: GET_ACTION_DETAILS_LIST_REQUEST
  });
  export const getActionDetailsListSuccess = payload => ({
    type: GET_ACTION_DETAILS_LIST_SUCCESS,
    payload
  });
  export const getActionDetailsListFailure = payload => ({
    type: GET_ACTION_DETAILS_LIST_FAILURE,
    payload
  });

  export const updateActionDetailStatusRequest = () => ({
    type: UPDATE_ACTION_DETAIL_STATUS_REQUEST
  });
  export const updateActionDetailStatusSuccess = payload => ({
    type: UPDATE_ACTION_DETAIL_STATUS_SUCCESS,
    payload
  });
  export const updateActionDetailStatusFailure = payload => ({
    type: UPDATE_ACTION_DETAIL_STATUS_FAILURE,
    payload
  });

  export const actionDataDetailAction = payload => {
    return {
      type: 'ACTIONDETAILSDATA',
      payload
    };
  };
  
  export const actionDetailErrorAction = payload => {
    return {
      type: 'ACTIONDETAILSERROR',
      payload
    };
  };
  
  export const addActionDetailsAPI = (payload, successData, errorMSg, headers = {}) => {
    return dispatch => {
      dispatch(actionDetailsRequest());
      return request(
        'POST',
        `${REACT_APP_API_URL + '/add-action-details'}`,
        payload,
        "",
        headers
      )
        .then(res => {
          if (res?.data.status === 200) {
            successData(res);
            dispatch(actionDetailsSuccess(res));
          } else {
            errorMSg(res);
          }
        })
        .catch(err => {
          errorMSg(err?.res);
          dispatch(actionDetailsFailure('Something went wrong'));
          throw err;
        });
    }
  }

  export const getActionDetailsListAPI = (payload, successData, errorMSg, headers = {}) => {
    return dispatch => {
      dispatch(getActionDetailsListRequest());
      return request(
        'POST',
        `${REACT_APP_API_URL + '/get-action-details-list'}`,
        payload,
        "",
        headers
      )
        .then(res => {
          if (res?.data.status === 200) {
            successData(res);
            dispatch(getActionDetailsListSuccess(res.data.data.actionDetailsList));
          } else {
            errorMSg(res);
          }
        })
        .catch(err => {
          errorMSg(err?.res);
          dispatch(getActionDetailsListFailure('Something went wrong'));
          throw err;
        });
    };
  };

  export const updateActionDetailAPI = (payload, successData, errorMSg, headers = {}) => {
    return dispatch => {
      dispatch(updateActionDetailsRequest());
      return request(
        'POST',
        `${REACT_APP_API_URL + '/update-action-details'}`,
        payload,
        "",
        headers
      )
        .then(res => {
          if (res?.data.status === 200) {
            successData(res);
            dispatch(updateActionDetailsSuccess(res));
          } else {
            errorMSg(res);
          }
        })
        .catch(err => {
          errorMSg(err?.res);
          dispatch(updateActionDetailsFailure('Something went wrong'));
          throw err;
        });
    };
  };

  export const updateActionDetailStatusAPI = (payload, successData, errorMSg, headers = {}) => {
    return dispatch => {
      dispatch(updateActionDetailStatusRequest());
      return request(
        'POST',
        `${REACT_APP_API_URL + '/update-action-detail-status'}`,
        payload,
        "",
        headers
      )
        .then(res => {
          if (res?.data.status === 200) {
            successData(res);
            dispatch(updateActionDetailStatusSuccess(res));
          } else {
            errorMSg(res);
          }
        })
        .catch(err => {
          errorMSg(err?.res);
          dispatch(updateActionDetailStatusFailure('Something went wrong'));
          throw err;
        });
    };
  };