import React, { useEffect, useState } from 'react';
import TabPage from 'components/common/TabPage';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { formChangedAction } from 'actions';
import $ from 'jquery';
import {
  updateUserDetailAPI,
  userDetailsAction,
  userDetailsErrorAction,
  userListAPI,
  userRegistrationAPI
} from 'actions/SecurityUserAssignment/SecurityUserAssignmentAction';
import {
  addRoleAPI,
  addSecurityUserRoleMasterAPI,
  deleteSecurityUserRoleMasterAPI
} from 'actions/SecurityUserRoleMaster/securityUserRoleMasterAction';
import request from 'components/Utils/Request';
import { selectedMenusAction } from 'actions/SecurityMenuRoleAsscociation/securityMenuRoleAssociation';
import TabPage1 from 'components/common/TabPage1';

const tabArray = ['User List', 'User Details'];

const listColumnArray = [
  { accessor: 'sl', Header: 'S. No' },
  { accessor: 'userName', Header: 'User Name',
    Cell: ({ value }) => (
      <div className="truncate-text" title={value}>
        {value}
      </div>
    )
   },
  { accessor: 'mobileNo', Header: 'Mobile' },
  { accessor: 'email', Header: 'Email' },
  { accessor: 'countryName', Header: 'Country Name' },
  { accessor: 'stateName', Header: 'State Name' },
  { accessor: 'status', Header: 'Status' }
];

export const User = () => {
  const dispatch = useDispatch();
  const [userList, setUserList] = useState([]);
  // const [perPage, setPerPage] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [searchText, setSearchText] = useState('');
  var roles = JSON.parse(localStorage.getItem('UserRoles'));

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  const userDetailsReducer = useSelector(
    state => state.rootReducer.securityUserAssignment
  );
  const userData = userDetailsReducer.userDetails;

  const formChangedReducer = useSelector(
    state => state.rootReducer.formChangedReducer
  );
  var formChangedData = formChangedReducer.formChanged;

  let isFormChanged = Object.values(formChangedData).some(
    value => value === true
  );

  const selectedMenusReducer = useSelector(
    state => state.rootReducer.selectedMenusReducer
  );
  var selectedMenuItems = selectedMenusReducer.selectedMenus;

  const [formHasError, setFormError] = useState(false);
  const [activeTabName, setActiveTabName] = useState();
  
  useEffect(() => {
    getUserList(1, pageSize);
  }, []);

  const clearUserReducers = () => {
    dispatch(userDetailsAction(undefined));
    dispatch(userDetailsErrorAction(undefined));
    dispatch(formChangedAction(undefined));
    dispatch(selectedMenusAction([]));
  };

  $('[data-rr-ui-event-key*="User List"]')
    .off('click')
    .on('click', function () {
      let isDiscard = $('#btnDiscard').attr('isDiscard');
      if (isDiscard != 'true' && isFormChanged) {
        setModalShow(true);
        setTimeout(function () {
          $('[data-rr-ui-event-key*="' + activeTabName + '"]').trigger('click');
        }, 50);
      } else {
        $('#btnNew').show();
        $('#btnSave').hide();
        $('#btnCancel').hide();
        $('[data-rr-ui-event-key*="User Details"]').attr('disabled', true);
        $('[data-rr-ui-event-key*="User List"]').attr('disabled', false);
        $('#addUserDetailsForm').get(0).reset();
        localStorage.removeItem('EncryptedResponseUserId');
        $('#btnDiscard').attr('isDiscard', false);
        clearUserReducers();
      }
    });

  $('[data-rr-ui-event-key*="User Details"]')
    .off('click')
    .on('click', function () {
      setActiveTabName('User Details');
      $('#btnNew').hide();
      $('#btnSave').show();
      $('#btnCancel').show();
    });

  const newDetails = () => {
    $('[data-rr-ui-event-key*="User Details"]').attr('disabled', false);
    $('[data-rr-ui-event-key="User Details"]').trigger('click');
    $('#btnSave').attr('disabled', false);
    clearUserReducers();
  };

  const cancelClick = () => {
    $('#btnExit').attr('isExit', 'false');
    if (isFormChanged) {
      setModalShow(true);
    } else {
      $('[data-rr-ui-event-key*="User List"]').trigger('click');
    }
  };

  const userValidation = () => {
    const advocateErr = {};
    const userNameErr = {};
    const emailErr = {};
    const countryErr = {};
    const stateErr = {};
    const loginIdErr = {};
    const mobileNoErr = {};
    const selectedMenuItemsErr = {};

    let isValid = true;

    if(roles == "ADMIN"){
    if (!userData.encryptedUserId && !userData.encryptedAdvocateCode) {
      advocateErr.empty = 'Select advocate';
      isValid = false;
      setFormError(true);
    }
  }

    if (!userData.userName) {
      userNameErr.nameEmpty = 'Enter user name';
      isValid = false;
      setFormError(true);
    }

    if (!userData.loginId) {
      loginIdErr.chamberNoEmpty = 'Enter login id';
      isValid = false;
      setFormError(true);
    }

    if (!userData.countryCode) {
      countryErr.empty = 'Select country';
      isValid = false;
      setFormError(true);
    }

    if (!userData.stateCode) {
      stateErr.empty = 'Select state';
      isValid = false;
      setFormError(true);
    }

    if (!userData.mobileNo) {
      mobileNoErr.mobileNoErr = 'Enter mobile no';
      isValid = false;
      setFormError(true);
    } else if (!/^(?!0)[0-9]{10}$/.test(userData.mobileNo)) {
      mobileNoErr.phoneInvalid =
        'Invalid mobile number, number should be of 10 digits should not start with 0';
      isValid = false;
    }

    if (!userData.email) {
      emailErr.empty = 'Enter email address';
      isValid = false;
      setFormError(true);
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userData.email)
    ) {
      emailErr.invalid = 'Please enter valid email address';
      isValid = false;
      setFormError(true);
    }

    if (selectedMenuItems.length <= 0) {
      selectedMenuItemsErr.selectedMenuItemsEmpty =
        'Select atleast one item';
      isValid = false;
      setFormError(true);
      toast.error('Select atleast one menu tree item', {
        theme: 'colored',
        autoClose: 10000
      });
    }

    if (!isValid) {
      var errorObject = {
        advocateErr,
        userNameErr,
        emailErr,
        countryErr,
        stateErr,
        loginIdErr,
        mobileNoErr,
        selectedMenuItemsErr
      };
      dispatch(userDetailsErrorAction(errorObject));
    }

    return isValid;
  };

  const addUserDetails = async () => {
    if (userValidation()) {
      const Data = {
        EncryptedAdvocateCode: userData.encryptedAdvocateCode ? userData.encryptedAdvocateCode : localStorage.getItem('EncryptedAdvocateCode'),
        LoggedInEncryptedSecurityUserId: localStorage.getItem(
          'EncryptedSecurityUserId'
        ),
        UserName: userData.userName,
        UserAddress1: userData.userAddress1
          ? userData.userAddress1.toUpperCase()
          : '',
        UserAddress2: userData.userAddress2
          ? userData.userAddress2.toUpperCase()
          : '',
        CountryCode: userData.countryCode,
        ActiveStatus: userData.status == 'Active' ? 'A' : 'S',
        StateCode: userData.stateCode,
        EmailId: userData.email,
        MobileNo: userData.mobileNo,
        LoginId: userData.loginId,
        AddUser: localStorage.getItem('LoginUserName'),
        treeIds: selectedMenuItems
      };
      const keys = [
        'UserName',
        'UserAddress1',
        'UserAddress2',
        'EmailId',
        'LoginId',
        'AddUser'
      ];
      for (const key of Object.keys(Data).filter(key => keys.includes(key))) {
        Data[key] = Data[key] ? Data[key].toUpperCase() : '';
      }
      setIsLoading(true);
    await  dispatch(
        userRegistrationAPI(
          Data,
          response => {
            setIsLoading(false);
            toast.success(` ${response.data.message}`, {
              theme: 'colored',
              autoClose: 10000
            });
            // if (response.data.data.encryptedUserId) {
            //   AddRoleTypeAPI(response.data.data.encryptedUserId)
            // }
            updateCallback(true, response.data.data.encryptedUserId);
            setModalShow(false);
            localStorage.setItem(
              'EncryptedResponseUserRegistrationId',
              response.data.data.encryptedUserId
            );
          },
          err => {
            setIsLoading(false)
            toast.error(err?.data.message, {
              theme: 'colored',
              autoClose: 10000
            });
          },
          headers
        )
      );
    }
  };

  const AddRoleTypeAPI = async( userId) => {
    var Data = {
      UserRoleId: 0,
      EncryptedUserId: userId,
      ActiveStatus: 'A',
      AddUser: localStorage.getItem('LoginUserName')
    };
  await  dispatch(
      addRoleAPI(
        Data,
        response => {
          setIsLoading(false);
          setModalShow(false);
        },
        err => {
          toast.error(err?.data.message, {
            theme: 'colored',
            autoClose: 10000
          });
        },
        headers
      )
    );
  };

  const updateCallback = (isAddUser = false, encryptedUserId) => {
    setModalShow(false);
    dispatch(userDetailsErrorAction(undefined));
    dispatch(formChangedAction(undefined));

    if (!isAddUser) {
      toast.success('User Details updated successfully!', {
        theme: 'colored'
      });
    } else {
      dispatch(
        userDetailsAction({
          ...userData,
          encryptedUserId: encryptedUserId,
          treeIds: selectedMenuItems
        })
      );
    }

    $('#btnSave').attr('disabled', true);
    setTimeout(() => {
      getUserList(1, pageSize);
    }, 100);

    $('[data-rr-ui-event-key*="' + activeTabName + '"]').trigger('click');
  };

  if (userData.status && $('#txtStatus').val()) {
    $('#txtStatus option:contains(' + userData.status + ')').prop(
      'selected',
      true
    );
  }

  const updateUserDetails = async () => {
    let addUserRoleMenuTreeId = [];
    let deleteUserRoleMenuTreeId = [];

    if (userValidation()) {
      let uniqueTreeIds = [...new Set(selectedMenuItems)];

      if (userData.treeIds && userData.treeIds.length > 0) {
        for (let i = 0; i < uniqueTreeIds.length; i++) {
          if (!userData.treeIds.includes(uniqueTreeIds[i])) {
            addUserRoleMenuTreeId.push(uniqueTreeIds[i]);
          }
        }

        for (let i = 0; i < userData.treeIds.length; i++) {
          if (!uniqueTreeIds.includes(userData.treeIds[i])) {
            deleteUserRoleMenuTreeId.push(userData.treeIds[i]);
          }
        }
      } else if (uniqueTreeIds.length > 0) {
        addUserRoleMenuTreeId = uniqueTreeIds;
      }

      const Data = {
        EncryptedUserId: userData.encryptedUserId
          ? userData.encryptedUserId
          : localStorage.getItem('EncryptedResponseUserRegistrationId'),
        EncryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode'),
        UserName: userData.userName,
        UserAddress1: userData.userAddress1 ? userData.userAddress1 : '',
        UserAddress2: userData.userAddress2 ? userData.userAddress2 : '',
        CountryCode: userData.countryCode,
        StateCode: userData.stateCode,
        EmailId: userData.email,
        MobileNo: userData.mobileNo,
        ActiveStatus: userData.status == 'Active' ? 'A' : 'S',
        ModifyUser: localStorage.getItem('LoginUserName')
      };
      const keys = [
        'UserName',
        'UserAddress1',
        'UserAddress2',
        'EmailId',
        'LoginId',
        'ModifyUser'
      ];
      for (const key of Object.keys(Data).filter(key => keys.includes(key))) {
        Data[key] = Data[key] ? Data[key].toUpperCase() : '';
      }

      setIsLoading(true);
      var hasError = false;

      if (formChangedData.userUpdate) {
      await  dispatch(
          updateUserDetailAPI(
            Data,
            response => {
              if (response) {
                setIsLoading(false);
                setModalShow(false);
                // updateCallback();
              } else {
                setIsLoading(false);
                setModalShow(false);
              }
            },
            err => {
              toast.error(err?.data.message, {
                theme: 'colored',
                autoClose: 10000
              });
              hasError = true;
            },
            headers
          )
        );
      }

      if (
        !hasError &&
        (formChangedData.menuItemAdd || formChangedData.menuItemDelete)
      ) {
        if (!hasError && formChangedData.menuItemDelete) {
          if (deleteUserRoleMenuTreeId) {
            var deleteUserRoleMenuTreeIdIndex = 1;

            for (let i = 0; i < deleteUserRoleMenuTreeId.length; i++) {
              const deleteTreeId = deleteUserRoleMenuTreeId[i];
              const data = {
                encryptedSecurityUserId: userData.encryptedUserId,
                treeId: deleteTreeId
              };

          await  dispatch(
                deleteSecurityUserRoleMasterAPI(
                  data,
                  response => {
                    console.log(response.data.message);
                  },
                  err => {
                    toast.error(err?.data.message, {
                      theme: 'colored',
                      autoClose: 10000
                    });
                    hasError = true;
                  },
                  headers
                )
              );
              if (hasError) break;
            }
            deleteUserRoleMenuTreeIdIndex++;
          }
        }

        if (
          !hasError &&
          formChangedData.menuItemAdd &&
          addUserRoleMenuTreeId.length > 0
        ) {
          const requestData = {
            loggedInEncryptedSecurityUserId: localStorage.getItem(
              'EncryptedSecurityUserId'
            ),
            encryptedSecurityUserId: userData.encryptedUserId,
            treeIds: addUserRoleMenuTreeId,
            addUser: localStorage.getItem('LoginUserName').toUpperCase()
          };

          dispatch(
         await addSecurityUserRoleMasterAPI(
              requestData,
              response => {
                console.log(response.data.message);
              },
              err => {
                toast.error(err?.data.message, {
                  theme: 'colored',
                  autoClose: 10000
                });
                hasError = true;
              },
              headers
            )
          );
        }
      }

      if (!hasError) {
        updateCallback();        
        dispatch(
          userDetailsAction({
            ...userData,
            treeIds: uniqueTreeIds
          })
        );
      }
    }
  };

  const getUserList = async (page, size = pageSize, searchText) => {
    const data = {
      pageNumber: page,
      pageSize: size,
      SearchText: searchText ? searchText : "",
      advocateCode: roles.includes("ADMIN") ? "" : localStorage.getItem("EncryptedAdvocateCode")
    }
    setIsLoading(true);
   await dispatch(
      userListAPI(
        data,
        response => {
          setIsLoading(false);
          setUserList(response.data.data.userList);
          setTotalCount(response.data.data.totalCount);
        },
        err => {
          setIsLoading(false);
          setUserList([]);
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  const exitModule = () => {
    $('#btnExit').attr('isExit', 'true');
    if (isFormChanged) {
      setModalShow(true);
    } else {
      window.location.href = '/dashboard';
    }
  };

  const discardChanges = () => {
    $('#btnDiscard').attr('isDiscard', 'true');
    if ($('#btnExit').attr('isExit') == 'true')
      window.location.href = '/dashboard';
    else $('[data-rr-ui-event-key*="List"]').trigger('click');

    setModalShow(false);
  };

  return (
    <>
      {isLoading ? (
        <Spinner
          className="position-absolute start-50 loader-color"
          animation="border"
        />
      ) : null}

      {modalShow && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Do you want to save changes?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={
                !userData.encryptedUserId ? addUserDetails : updateUserDetails
              }
            >
              Save
            </Button>
            <Button
              variant="danger"
              id="btnDiscard"
              onClick={() => discardChanges()}
            >
              Discard
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <TabPage1
        listData={userList}
        listColumnArray={listColumnArray}
        tabArray={tabArray}
        module="User"
        saveDetails={
          userData.encryptedUserId ? updateUserDetails : addUserDetails
        }
        newDetails={newDetails}
        cancelClick={cancelClick}
        exitModule={exitModule}
        pageSize={pageSize}
        setPageSize={(value)=> setPageSize(value)}
        searchText={searchText}
        setSearchTextValue={(value)=> setSearchText(value)}
        fetchList = {getUserList}
        listCount={totalCount}
      />
    </>
  );
};

export default User;
