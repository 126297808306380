import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import defaultAvatar from 'assets/img/default-avatar.jpg';
import Avatar from 'components/common/Avatar';
import { viewAdvocateDetailAPI } from 'actions/AdvocateRegistration/advocateRegistrationAction';
import { useDispatch } from 'react-redux';
import { advocateDetailsAction } from 'actions';

const ProfileDropdown = () => {
  const dispatch = useDispatch();
  const userName = localStorage.getItem("LoginUserName");
  const [profileImage, setProfileImage] = useState('');

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  useEffect(() => {
      viewAdvocateProfileDetail();
    }, []);

  const viewAdvocateProfileDetail = async () => {
    const requestData = {
      EncryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode')
    };
    await dispatch(
      viewAdvocateDetailAPI(
        requestData,
        res => {
          dispatch(advocateDetailsAction(res.data.data));
          setProfileImage(res.data.data.advocatePicFileURL);
        },
        err => {
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle bsPrefix="toggle" as={Link} className="pe-0 ps-2 nav-link">
        <Avatar src={profileImage || defaultAvatar} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item disabled>
            <b>{userName}</b>
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/profile">
            Profile
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/logout">
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;