import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import FalconComponentCard from '../FalconComponentCard';
import { useDispatch } from 'react-redux';
import { getActionDetailsListAPI } from 'actions/ActionDetails/actionDetailsAction';
import AdvanceTableComponent2 from '../advance-table-2/AdvanceTableComponent2';

const columns = [
  {
    accessorKey: 'clientName',
    header: 'Client Name',
    meta: { headerProps: { className: 'text-dark action-card-clientname' } },
    cell: ({ getValue }) => (
      <span title={getValue()} className="truncate-text">
        {getValue()}
      </span>
    )
  },
  {
    accessorKey: 'caseNo',
    header: 'Case No.',

    meta: { headerProps: { className: 'text-dark action-card-caseno' } },
    cell: ({ getValue }) => (
      <span title={getValue()} className="truncate-text">
        {getValue()}
      </span>
    )
  },
  {
    accessorKey: 'date',
    header: 'Action Date',
    meta: { headerProps: { className: 'text-dark' } }
  },
  {
    accessorKey: 'actionItem',
    header: 'Action Description',
    meta: { headerProps: { className: 'text-dark ' } },
    cell: ({ getValue }) => (
      <span title={getValue()} className="truncate-text">
        {getValue()}
      </span>
    )
  },
  {
    accessorKey: 'courtName',
    header: 'Court Name',
    meta: { headerProps: { className: 'text-dark action-card-courtname' } }
  },
  {
    accessorKey: 'reminderStatus',
    header: 'Status',
    meta: { headerProps: { className: 'text-dark' } }
  }
];

const ActionDetailListCard = () => {
  const dispatch = useDispatch();
  const [actionDetailList, setActionDetailList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState('');

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  useEffect(() => {
    getActionDetailList(1, pageSize);
  }, []);

  const getActionDetailList = async (page, size = pageSize, searchText) => {
    const data = {
      pageNumber: page,
      pageSize: size,
      SearchText: searchText ? searchText : '',
      EncryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode')
    };
    setIsLoading(true);
    await dispatch(
      getActionDetailsListAPI(
        data,
        response => {
          setIsLoading(false);
          setActionDetailList(response.data.data.actionDetailsList);
          setTotalCount(response.data.data.totalCount);
          $(".action-list-card table thead").removeClass("action-head");
        },
        err => {
          setIsLoading(false);
          $(".action-list-card table thead").addClass("action-head");
          setActionDetailList([]);
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  return (
    <>
      <Card className="card-dashbord-bg mb-2  mt-2 border-0">
        <Row className="align-items-center m-0 p-0">
          <Col md={9} className="p-0">
            <Card.Header className="fw-bolder text-center ms-8 case-schedule-card-dash p-1 text-white">
              Action Details
            </Card.Header>
          </Col>
        </Row>
      </Card>
      <FalconComponentCard className="action-list-card">
        {isLoading ? (
          <Spinner
            className="position-absolute start-50 loader-color "
            animation="border"
          />
        ) : null}
        <FalconComponentCard.Body className="bg-white mb-2 mt-2 remove-border-client action-padding action-list-card-body action-table-advance fc-scroller scrollbar fc-scroller-liquid-absolute">   
          <AdvanceTableComponent2
            data={actionDetailList}
            columns={columns}
            pageSize={pageSize}
            setPageSize={0}
            searchText={searchText}
            setSearchTextValue={value => setSearchText(value)}
            fetchList={getActionDetailList}
            dataCount={totalCount}
          />
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default ActionDetailListCard;