import {
  ADVOCATECLIENTCASE_LIST_REQUEST,
  ADVOCATECLIENTCASE_LIST_SUCCESS,
  ADVOCATECLIENTCASE_LIST_FAILURE,
  ADD_ADVOCATECLIENTCASE_REQUEST,
  ADD_ADVOCATECLIENTCASE_SUCCESS,
  ADD_ADVOCATECLIENTCASE_FAILURE,
  CLIENT_CASE_DETAIL_LIST_REQUEST,
  CLIENT_CASE_DETAIL_LIST_SUCCESS,
  CLIENT_CASE_DETAIL_LIST_FAILURE,
  DELETE_ADVOCATECLIENTCASE_REQUEST,
  DELETE_ADVOCATECLIENTCASE_SUCCESS,
  DELETE_ADVOCATECLIENTCASE_FAILURE,
  CASE_NEXT_DATE_LIST_REQUEST,
  CASE_NEXT_DATE_LIST_SUCCESS,
  CASE_NEXT_DATE_LIST_FAILURE
} from 'actions/AdvocateClientCase/AdvocateClientCaseAction';

const initialState = {
  advocateClientCaseList: [],
  clientCaseList: [],
  advocateClientCaseDetails: {},
  advocateClientCaseData: {},
  caseDeleted: false,
  caseNextDateList: []
};

export default function advocateClientCaseReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case ADVOCATECLIENTCASE_LIST_REQUEST:
      return {
        ...state
      };
    case ADVOCATECLIENTCASE_LIST_SUCCESS:
      return {
        ...state,
        advocateClientCaseList: action.payload
      };
    case ADVOCATECLIENTCASE_LIST_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case ADD_ADVOCATECLIENTCASE_REQUEST:
      return {
        ...state
      };
    case ADD_ADVOCATECLIENTCASE_SUCCESS:
      return {
        ...state,
        advocateClientCaseDetails: action.payload
      };
    case ADD_ADVOCATECLIENTCASE_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case DELETE_ADVOCATECLIENTCASE_REQUEST:
      return {
        ...state
      };
    case DELETE_ADVOCATECLIENTCASE_SUCCESS:
      return {
        ...state
      };
    case DELETE_ADVOCATECLIENTCASE_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case CLIENT_CASE_DETAIL_LIST_REQUEST:
      return {
        ...state
      };
    case CLIENT_CASE_DETAIL_LIST_SUCCESS:
      return {
        ...state,
        clientCaseList: action.payload
      };
    case CLIENT_CASE_DETAIL_LIST_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case CASE_NEXT_DATE_LIST_REQUEST:
      return {
        ...state
      };
    case CASE_NEXT_DATE_LIST_SUCCESS:
      return {
        ...state,
        caseNextDateList: action.payload
      };
    case CASE_NEXT_DATE_LIST_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case 'DELETE_CASE_SUCCESS':
      return {
        ...state,
        caseDeleted: true
      };
    case 'RESET_CASE_DELETED':
      return {
        ...state,
        caseDeleted: false
      };
    case 'ADVOCATECLIENTCASEDATA':
      if (!action.payload) {
        return initialState;
      } else {
        return {
          ...state,
          advocateClientCaseData: action.payload
        };
      }
    case 'ADVOCATECLIENTCASEDETAILS':
      if (!action.payload) {
        return initialState;
      } else {
        return {
          ...state,
          advocateClientCaseDetails: action.payload
        };
      }
    default:
      return state;
  }
}
