import React, { useState, useEffect } from 'react';
import { Button, Table, Form, Modal, Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { formChangedAction } from 'actions';
import { toast } from 'react-toastify';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { advocateDealingDetailAction } from 'actions/AdvocateDealing/advocateDealingAction';
import { getLitigationTypeMasterListAPI } from 'actions/LitigationTypesMaster/litigationTypesMasterAction';
import { getLitigationTypeDetailListAPI } from 'actions/LitigationTypesDetail/litigationTypesDetailAction';

export const AdvocateDealing = () => {
  const dispatch = useDispatch();
  const [formHasError, setFormError] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [paramsData, setParamsData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [litigationTypeMasterList, setLitigationTypeMasterList] = useState([]);
  const [litigationTypeDetailsList, setLitigationTypeDetailsList] = useState([]);
  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  const columnsArray = [
    'S.No',
    'Litigation Name',
    'Litigation Types Detail',
    'Action'
  ];

  const emptyRow = {
    id: rowData.length + 1,
    encryptedAdvocateCode: localStorage.getItem(
      'EncryptedResponseAdvocateCode'
    ),
    litigationCode: '',
    subLitigationCode: ''
  };

  const advocateDealingReducer = useSelector((state) => state.rootReducer.advocateDealingReducer);
  const advocateDealingData = advocateDealingReducer.advocateDealingDetails;

  const advocateDetailsErrorReducer = useSelector((state) => state.rootReducer.advocateDetailsErrorReducer);
  const advocateError = advocateDetailsErrorReducer.advocateDetailsError;

  const formChangedReducer = useSelector((state) => state.rootReducer.formChangedReducer);
  var formChangedData = formChangedReducer.formChanged;

  useEffect(() => {
    if (advocateDealingReducer.advocateDealingDetails.length > 0) {
      setRowData(advocateDealingData);
      setTimeout(function () {
        setSubLitigationValue();
      }, 50);
    }

    if (!litigationTypeMasterList || litigationTypeMasterList.length <= 0) {
      getLitigationList();
    }
  }, [advocateDealingData, advocateDealingReducer]);

  const setSubLitigationValue = () => {
    advocateDealingData.map((row, index) => {
      getLitigationTypeDetailList(row.litigationCode, index);
    });
  };

  const getLitigationList = async () => {
    setIsLoading(true);
    const data = {
      isDropDown: true,
    };
    await dispatch(
      getLitigationTypeMasterListAPI(
        data,
        res => {
          setIsLoading(false);
          let litigationData = [];

          if (res.data.data.litigationList.length > 0)
            res.data.data.litigationList.forEach(data => {
              litigationData.push({
                key: data.litigationDetailName,
                value: data.litigationCode
              });
            });

          setLitigationTypeMasterList(litigationData);
        },
        err => {
          setLitigationTypeMasterList([]);
          setIsLoading(false);
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  const getLitigationTypeDetailList = async (litigationCode, index) => {
    const data = {
      LitigationCode: litigationCode,
      isDropDown:true
    };
    setIsLoading(true);
    await dispatch(
      getLitigationTypeDetailListAPI(
        data,
        res => {
          setIsLoading(false);
          let litigationDetailData = [];

          if (res.data.data.litigationTypeDetailList.length > 0)
            res.data.data.litigationTypeDetailList.forEach(data => {
              litigationDetailData.push({
                key: data.litigationTypeDetailName,
                value: data.subLitigationCode
              });
            });

          setLitigationTypeDetailsList(prevList => {
            const newLitigationDetailList = [...prevList];
            newLitigationDetailList[index] = litigationDetailData;
            return newLitigationDetailList;
          });
        },
        err => {
          setLitigationTypeDetailsList(prevList => {
            const newLitigationDetailList = [...prevList];
            newLitigationDetailList[index] = [];
            return newLitigationDetailList;
          });
          setIsLoading(false);
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  const validateLitigationDetailsForm = () => {
    let isValid = true;
    if (advocateDealingData && advocateDealingData.length > 0) {
      advocateDealingData.forEach((row, index) => {
        if (!row.litigationCode || !row.subLitigationCode) {
          isValid = false;
          setFormError(true);
        }
      });
    }

    if (isValid) {
      setFormError(false);
    }

    return isValid;
  };

  const handleAddRow = () => {
    let formValid = validateLitigationDetailsForm();
    if (formValid) {
      advocateDealingData.unshift(emptyRow);
      dispatch(advocateDealingDetailAction(advocateDealingData));
    }
  };

  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    var litigationDetails = [...rowData];
    litigationDetails[index][name] = value;
    litigationDetails = Object.keys(rowData).map(key => {
      return rowData[key];
    });

    if (name == 'litigationCode') {
      litigationDetails[index].subLitigationCode = '';
      value && getLitigationTypeDetailList(value, index);
    }
    const selectedLitigationCode = litigationDetails[index].litigationCode;
    const selectedSubLitigationCode = litigationDetails[index].subLitigationCode;

    if (selectedLitigationCode && selectedSubLitigationCode) {
      const duplicateData = rowData.some((detail, i) =>
        i !== index &&
        detail.litigationCode === selectedLitigationCode &&
        detail.subLitigationCode === selectedSubLitigationCode
      );

      if (duplicateData) {
        toast.error(`Litigation type detail already exists`, {
          theme: 'colored'
        });
        litigationDetails[index][name] = '';
        setRowData(litigationDetails);
        return;
      }
    }
    dispatch(advocateDealingDetailAction(litigationDetails));

    if (litigationDetails[index].encryptedAdvocateDealingId) {
      dispatch(
        formChangedAction({
          ...formChangedData,
          advocateDealingUpdate: true
        })
      );
    } else {
      dispatch(
        formChangedAction({
          ...formChangedData,
          advocateDealingAdd: true
        })
      );
    }
  };

  const ModalPreview = (encryptedAdvocateDealingId, rowId) => {
    setModalShow(true);
    setParamsData({ encryptedAdvocateDealingId, rowId });
  };

  const deleteAdvocateDealingDetails = () => {
    if (!paramsData) return false;

    var objectIndex = advocateDealingReducer.advocateDealingDetails.findIndex(x => x.encryptedAdvocateDealingId == paramsData.encryptedAdvocateDealingId);
    advocateDealingReducer.advocateDealingDetails.splice(objectIndex, 1);

    var deleteAdvocateDealingId = localStorage.getItem("DeleteAdvocateDealingId");

    if (paramsData.encryptedAdvocateDealingId) {
      var deleteAdvocateDealingIds = deleteAdvocateDealingId ? deleteAdvocateDealingId + "," + paramsData.encryptedAdvocateDealingId : paramsData.encryptedAdvocateDealingId;
      localStorage.setItem("DeleteAdvocateDealingId", deleteAdvocateDealingIds);
    }

    toast.success("Advocate dealing details deleted successfully", {
      theme: 'colored'
    });
    dispatch(advocateDealingDetailAction(advocateDealingData));
    dispatch(formChangedAction({
      ...formChangedData,
      advocateDealingDelete: true
    }))

    setModalShow(false);
  };

  return (
    <>
      {modalShow && paramsData && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Are you sure, you want to delete this Advocate dealing detail?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={() => setModalShow(false)}>Cancel</Button>
            <Button variant="danger" onClick={() => deleteAdvocateDealingDetails()}>Delete</Button>
          </Modal.Footer>
        </Modal>
      )}

      <Card className="h-100 mb-2 advocate-dealing-card">
        <FalconCardHeader
          title="Advocate Dealing"
          titleTag="h6"
          className="py-2"
          light
          endEl={
            <Flex>
              <div>
                <Button
                  variant="primary"
                  size="sm"
                  className="btn-reveal"
                  type="button"
                  onClick={handleAddRow}
                >
                  <i className="fa-solid fa-plus" />
                </Button>
              </div>
            </Flex>
          }
        />
        {
          advocateDealingData && advocateDealingData.length > 0 &&
          <Card.Body className="position-relative pb-0 p3px full-tab-card-body">
            <Form
              noValidate
              validated={formHasError || advocateError && advocateError.advocateDealingErr.invalidDetail}
              className="details-form"
              id="AddAdvocateDealingDetailsForm"
            >
              <Table striped bordered responsive id="TableList" className="no-pb text-nowrap tab-page-table">
                <thead className='custom-bg-200'>
                  <tr>
                    {columnsArray.map((column, index) => (
                      <th className="text-left" key={index}>
                        {column}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody id="tbody" className="details-form">
                  {rowData.map((advocateDealingData, index) => (
                    <tr key={index}>
                      <td>
                        {index + 1}
                      </td>
                      <td key={index}>
                        <Form.Select id="txtLitigationName" name="litigationCode" className="form-control"
                          value={advocateDealingData.litigationCode}
                          onChange={(e) => handleFieldChange(e, index)} required>
                          <option value=''>Select Litigation</option>
                          {litigationTypeMasterList.map((option, index) => (
                            <option key={index} value={option.value}>{option.key}</option>
                          ))}
                        </Form.Select>
                      </td>
                      <td key={index}>
                        <Form.Select id="txtLitigationDetailsName" name="subLitigationCode" className="form-control"
                          value={advocateDealingData.subLitigationCode}
                          onChange={(e) => handleFieldChange(e, index)} required>
                          <option value=''>Select Litigation Detail</option>
                          {litigationTypeDetailsList[index] && litigationTypeDetailsList[index].map((option, mapIndex) => (
                            <option key={mapIndex} value={option.value}>{option.key}</option>
                          ))}

                        </Form.Select>
                      </td>

                      <td>
                        <FontAwesomeIcon icon={'trash'} className="fa-2x" onClick={() => { ModalPreview(advocateDealingData.encryptedAdvocateDealingId, advocateDealingData.id) }} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Form>
          </Card.Body>
        }
      </Card>
    </>
  );
};

export default AdvocateDealing;