import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row, Button, Table, Spinner } from 'react-bootstrap';
import Moment from "moment";
import { toast } from 'react-toastify';
import TablePagination from 'components/common/TablePagination';
import { clientListAPI } from 'actions/ClientRegistration/ClientRegistrationAction';
import { getClientCaseMasterListAPI } from 'actions/CaseNextDate/caseNextDateAction';
import { caseScheduleAction, generateCaseSchedulePdfAPI, getCaseScheduleListAPI, nextDatePdfAPI } from 'actions/CaseSchedule/caseScheduleAction';
import { courtMasterListAPI } from 'actions/CourtMaster/courtMasterAction';

export const CaseScheduleDetail = () => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [clientList, setClientList] = useState([]);
	const [clientCaseMasterList, setClientCaseMasterList] = useState([]);
	const [caseScheduleList, setCaseScheduleList] = useState([]);
	const [pageNumber, setPageNumber] = useState(1);
	const [courtMasterList, setCourtMasterList] = useState([]);
	const [pageSize, setPageSize] = useState(5);
	const [caseScheduleCount, setCaseScheduleCount] = useState(0);

	const [formHasError, setFormError] = useState(false);
	const selectedClientCode = localStorage.getItem('ClientCode');
	let pageCount = Math.ceil(caseScheduleCount / pageSize);
	const isPreviousDisabled = pageNumber === 1;
	const isNextDisabled = pageNumber === pageCount;
	let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
	const headers = {
		Authorization: `Bearer ${JSON.parse(token).value}`
	};
	const [mergedCaseScheduleList, setMergedCaseScheduleList] = useState([]);

	const caseScheduleReducer = useSelector((state) => state.rootReducer.caseScheduleReducer)
	var caseScheduleData = caseScheduleReducer.caseScheduleData;

	useEffect(() => {
		getCourtMasterList();
	}, [])

	useEffect(() => {
		if (clientList.length <= 0) {
			getClientList();
		}

		if (caseScheduleList && caseScheduleList.length > 0) {
			const mergedList = mergeRowsByNextDateLineName(caseScheduleList);
			setMergedCaseScheduleList(mergedList);
		} else {

			setMergedCaseScheduleList([]);
		}

	}, [caseScheduleList])

	const getClientList = async () => {
		const data = {
			pageNumber: 1,
			pageSize: 1,
			SearchText: '',
			EncryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode'),
			isDropDown: true
		};

		dispatch(
			clientListAPI(
				data,
				res => {
					let clientData = [];

					if (res.data.data.clientList.length > 0)
						res.data.data.clientList.forEach(client => {
							clientData.push({
								key: client.clientName,
								value: client.clientCode
							});
						});
					setClientList(clientData);
					setCaseScheduleList([])
					setMergedCaseScheduleList([])
				},
				err => {
					setClientList([]);
					setCaseScheduleList([])
					setMergedCaseScheduleList([])
					console.log(err?.data.message);
				},
				headers
			)
		);
	};

	const getClientCaseMasterList = async (clientCode) => {
		const data = {
			EncryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode'),
			ClientCode: clientCode ? clientCode : selectedClientCode
		};
		await dispatch(
			getClientCaseMasterListAPI(
				data,
				res => {
					let caseMasterData = [];
					if (res.data && res.data.data.caseMasterList.length > 0) {
						res.data.data.caseMasterList.forEach(data => {
							caseMasterData.push({
								key: `${data.caseNo} --- ${data.caseTitle}`,
								value: { clientCaseNo: data.caseNo, courtCode: data.courtCode },
							});

						});
						setClientCaseMasterList(caseMasterData);
					} else {
						setClientCaseMasterList([]);
					}
				},
				err => {
					setClientCaseMasterList([]);
				},
				headers
			)
		);
	};

	const getCourtMasterList = async () => {
		const data = {
			IsCaseSchedule: true
		}
		await dispatch(courtMasterListAPI(
			data,
			res => {
				let courtData = [];
				if (res.data && res.data.data.courtList.length > 0) {
					res.data.data.courtList.forEach(data => {
						courtData.push({
							key: data.courtName,
							value: data.courtCode
						});
					});
					setCourtMasterList(courtData);
				} else {
					setCourtMasterList([]);
				}
			},
			(err) => {
				setCourtMasterList([]);
			},
			headers
		));
	};

	const fetchCaseScheduleList = async ( newPageNumber, newPageSize) => {
		if (caseScheduleValidation()) {
			let requestData = {
				EncryptedAdvocateCode: localStorage.getItem("EncryptedAdvocateCode"),
				ClientCode: localStorage.getItem("ClientCode") ? localStorage.getItem("ClientCode") : caseScheduleData.clientCode != null || "" ? caseScheduleData.clientCode : "",
				FromDate: caseScheduleData.fromDate ? Moment(caseScheduleData.fromDate).format("YYYY-MM-DD") : Moment().format("YYYY-MM-DD"),
				ToDate: caseScheduleData.toDate ? Moment(caseScheduleData.toDate).format("YYYY-MM-DD") : Moment().format("YYYY-MM-DD"),
				ClientCaseNo: localStorage.getItem("ClientCaseNo") ? localStorage.getItem("ClientCaseNo") : caseScheduleData.clientCaseNo ? caseScheduleData.clientCaseNo : "",
				CourtCode: localStorage.getItem('CourtCode') ? localStorage.getItem('CourtCode') : caseScheduleData.courtCode ? caseScheduleData.courtCode : "",
				PageNumber: newPageSize ? 1 : newPageNumber ? newPageNumber : pageNumber,
				PageSize: newPageSize ? newPageSize : pageSize,
				isGeneratePDF: false
			}
			setIsLoading(true);
			await dispatch(
				getCaseScheduleListAPI(
					requestData,
					res => {
						if (res.data.caseScheduleList.length > 0) {
							setIsLoading(false);
							setCaseScheduleList(res.data.caseScheduleList)
							setCaseScheduleCount(res.data.caseScheduleCount)
						} else {
							setIsLoading(false);
							setCaseScheduleList([])
							setTimeout(function () {
								$('#no-case-schedule-message').html('No records found!');
							}, 100)
						}
					},
					err => {
						setIsLoading(false);
						setCaseScheduleList([])
						setTimeout(function () {
							$('#no-case-schedule-message').html('No records found!');
						}, 100)
						console.log(err?.data.message);
					},
					headers
				)
			);
		}
	}

	const GeneratePDFCaseSchedule = async (newPageNumber, newPageSize) => {
		if (caseScheduleValidation()) {
			let requestData = {
				EncryptedAdvocateCode: localStorage.getItem("EncryptedAdvocateCode"),
				ClientCode: localStorage.getItem("ClientCode") ? localStorage.getItem("ClientCode") : caseScheduleData.clientCode ? caseScheduleData.clientCode : "",
				FromDate: caseScheduleData.fromDate ? Moment(caseScheduleData.fromDate).format("YYYY-MM-DD") : Moment().format("YYYY-MM-DD"),
				ToDate: caseScheduleData.toDate ? Moment(caseScheduleData.toDate).format("YYYY-MM-DD") : Moment().format("YYYY-MM-DD"),
				ClientCaseNo: localStorage.getItem("ClientCaseNo") ? localStorage.getItem("ClientCaseNo") : caseScheduleData.clientCaseNo ? caseScheduleData.clientCaseNo : "",
				CourtCode: localStorage.getItem('CourtCode') ? localStorage.getItem('CourtCode') : caseScheduleData.courtCode ? caseScheduleData.courtCode : "",
				PageNumber: newPageSize ? 1 : newPageNumber ? newPageNumber : pageNumber,
				PageSize: newPageSize ? newPageSize : pageSize,
				isGeneratePDF: true
			}
			await dispatch(
				generateCaseSchedulePdfAPI(
					requestData,
					(res) => {
						console.log(res);
					},
					(err) => {
						console.error("Error occurred while generating PDF:", err?.message || err);
					},
					headers
				)
			);
		}
	}

	const downloadNextDateDetailPdf = () => {
		const requestData = {
			EncryptedAdvocateCode: localStorage.getItem("EncryptedAdvocateCode"),
		};
		dispatch(nextDatePdfAPI(requestData, headers, (res) => {
			if (res.headers['content-type'] != 'application/pdf') {
				toast.warning("No data available!", {
					theme: 'colored',
					autoClose: 10000
				});
			}
		}));
	};

	const handleFieldChange = (e) => {
		const { name, value } = e.target;
		if (name === 'clientCode') {
            setCaseScheduleCount(0)
			setCaseScheduleList([]);
			const selectedOption = e.target.options[e.target.selectedIndex];
			const selectedKey = selectedOption.dataset.key || selectedOption.label;
			localStorage.setItem('CaseScheduleClientName', selectedKey);
			localStorage.setItem('ClientCode', value);

			dispatch(
				caseScheduleAction({
					...caseScheduleData,
					clientCode: value,
					clientCaseNo: null,
					courtCode: null
				})
			);
			localStorage.removeItem('ClientCaseNo');
			localStorage.removeItem('CourtCode');
			getClientCaseMasterList(value);
		} else if (name === 'clientCaseNo') {

			dispatch(
				caseScheduleAction({
					...caseScheduleData,
					clientCaseNo: null,
					courtCode: null
				})
			);
			localStorage.removeItem('ClientCaseNo');
			localStorage.removeItem('CourtCode');
			const selectedOption = clientCaseMasterList.find(
				(option) => option.value.clientCaseNo === value
			);

			if (selectedOption) {
				const { courtCode } = selectedOption.value;
				localStorage.setItem('ClientCaseNo', value);
				localStorage.setItem('CourtCode', courtCode);

				dispatch(
					caseScheduleAction({
						...caseScheduleData,
						clientCaseNo: value,
						courtCode: courtCode || '',
					})
				);
			}

		} else if (name === 'courtCode') {
			localStorage.removeItem('CourtCode');
			dispatch(
				caseScheduleAction({
					...caseScheduleData,
					courtCode: value || '',
				})
			);

		} else {
			dispatch(
				caseScheduleAction({
					...caseScheduleData,
					[name]: value,
				})
			);
			setPageNumber(1);
		}
	};

	const caseScheduleValidation = () => {
		let isValid = true;


		if (caseScheduleData.fromDate > caseScheduleData.toDate) {
			toast.error("From Date cannot be greater than To Date", {
				theme: 'colored'
			});
			isValid = false;
			setFormError(true);
		}
		return isValid;
	};

	const mergeRowsByNextDateLineName = (list) => {
		const groupedByNextDateAndCourtName = {};
		list.forEach((item) => {
			const key = `${item.nextDate}`;

			if (!groupedByNextDateAndCourtName[key]) {
				groupedByNextDateAndCourtName[key] = [item];
			} else {
				groupedByNextDateAndCourtName[key].push(item);
			}
		});

		const mergedList = Object.values(groupedByNextDateAndCourtName);
		return mergedList;
	};

	const caseNextDateNavigate = (item) => {
		localStorage.setItem("CaseScheduleClientCode", item.clientCode);
		localStorage.setItem("CaseScheduleCaseNo", item.clientCaseNo);
		localStorage.setItem("CaseScheduleClientName", item.clientName);
		window.open(`/case-next-date/${item.caseId}`, "_blank");
	}

	const onPageClick = async ({ selected }) => {
		setPageNumber(selected + 1);
		fetchCaseScheduleList( selected + 1)
	}

	const handlePageSize = async (e) => {
		setPageSize(e.target.value);
		setPageNumber(1);
		fetchCaseScheduleList( 1, e.target.value)
	}

	const handlePreviousClick = async () => {
		setPageNumber(pageNumber - 1);
		fetchCaseScheduleList( pageNumber - 1)
	}

	const handleNextClick = async () => {
		setPageNumber(pageNumber + 1);
		fetchCaseScheduleList( pageNumber + 1)
	}

	return (
		<>
			{isLoading ? (
				<Spinner
					className="position-absolute start-50 loader-color"
					animation="border"
				/>
			) : null}

			<Form className="details-form" id="CaseScheduleDetailDashboard">
				<Row className='mb-1 ms-2 justify-content-left'>
					<Form.Label column className='col-auto'>
						Client Name
					</Form.Label>
					<Col className='col-auto'>

						<Form.Select id="txtClientCode" name="clientCode" onChange={handleFieldChange} value={caseScheduleData.clientCode || ''}>
							<option value=''>Select</option>
							{clientList.map((option, index) => (
								<option key={index} value={option.value}>{option.key}</option>
							))}
						</Form.Select>

					</Col>
					<Form.Label column className='col-auto'>Case No</Form.Label>
					<Col className="col-2">
						<Form.Select
							id="txtClientCaseNo"
							name="clientCaseNo"
							value={caseScheduleData.clientCaseNo || ''}
							onChange={(e) => handleFieldChange(e)}
						>
							<option value="">Select</option>
							{clientCaseMasterList.map((option, index) => (
								<option key={index} value={option.value.clientCaseNo}>
									{option.key}
								</option>
							))}
						</Form.Select>
					</Col>
					<Form.Label column className='col-auto'>Court Name</Form.Label>
					<Col className='col-3'>
						<Form.Select id="txtCourtCode" name="courtCode" value={caseScheduleData.courtCode || ""} onChange={handleFieldChange}
						>
							<option value=''>Select</option>
							{courtMasterList.map((option, index) => (
								<option key={index} value={option.value}>{option.key}</option>
							))}
						</Form.Select>
					</Col>
				</Row>

				<Row className='justify-content-left ms-2'>
					<Form.Label column className='col-auto'>
						From Date
					</Form.Label>
					<Col className='col-auto'>
						<Form.Control type='date' id="dtFromDate" name="fromDate" onChange={handleFieldChange}
							value={caseScheduleData.fromDate ? Moment(caseScheduleData.fromDate).format('YYYY-MM-DD') : Moment().format('YYYY-MM-DD')}
						/>
					</Col>
					<Form.Label column className='col-auto'>
						To Date
					</Form.Label>
					<Col className='col-auto'>
						<Form.Control type='date' id="dtToDate" name="toDate" onChange={handleFieldChange}
							value={caseScheduleData.toDate ? Moment(caseScheduleData.toDate).format('YYYY-MM-DD') : Moment().format('YYYY-MM-DD')}
						/>
					</Col>

					<Col className='col-auto'>
						<Button onClick={()=>fetchCaseScheduleList(pageNumber, pageSize)}>Show Case Report</Button>
					</Col>
					{ caseScheduleList.length > 0 ? 
					(<Col className='col-auto'>
						<Button onClick={() => GeneratePDFCaseSchedule()}>Generate PDF Report</Button>
					</Col>) : ""
}
					<Col className='col-auto'>
						<Button onClick={downloadNextDateDetailPdf}>Next Details</Button>
					</Col>
				</Row>

				{mergedCaseScheduleList && mergedCaseScheduleList.length > 0 ?
						<Row className="no-padding">
							<Table striped bordered responsive className="table-md overflow-hidden">
								<thead className='custom-bg-200'>
									<tr>
										<th>S. No</th>
										<th>Next Date</th>
										<th>Court Name</th>
										<th>Case Title</th>
										<th>Case No</th>
										<th>Case Type</th>
										<th>Judge Name</th>
										<th>Court No</th>
										<th> Purpose</th>
										<th>Previous Date</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{mergedCaseScheduleList.map((group, groupIndex) => (
										<React.Fragment key={groupIndex}>
											{group.map((item, index) => {
												const isFirstRowForCourt = index === 0 || item.courtName !== group[index - 1].courtName;
												const rowSpanCourtName = group.filter((g) => g.courtName === item.courtName).length;
												return (
													<tr key={`${groupIndex}_${index}`}>
														{index === 0 && (
															<>
																<td rowSpan={group.length}>{groupIndex + 1}</td>
																<td rowSpan={group.length}>{Moment(item.nextDate).format("DD-MMMM-YYYY")}</td>
															</>
														)}
														{isFirstRowForCourt && (
															<td rowSpan={rowSpanCourtName}>{item.courtName}</td>
														)}
														<td className="pl-5 td-truncate-text"  title={`${item.caseTitle} ${item.party ? `(${item.party})` : ''}`}>{item.caseTitle} {item.party && <span> (<span className='fw-bold'>{item.party}</span>)</span> || ""}</td>
														<td>{item.clientCaseNo}</td>
														<td title={item.caseTypeName} className='td-truncate-text'>{item.caseTypeName}</td>
														<td title={item.judgeName} className='td-truncate-text'>{item.judgeName ? item.judgeName : ""}</td>
														<td>{item.courtNo ? item.courtNo : ""}</td>
														<td>{item.purpose ? item.purpose : ""}</td>
														<td>{Moment(item.previousDate).format("DD-MMMM-YYYY")}</td>
														<td>
															<span
																onClick={(e) => caseNextDateNavigate(item)}
																className="btn">
																<span>
																	<svg
																		aria-hidden="true"
																		focusable="false"
																		data-prefix="fas"
																		data-icon="edit"
																		className="svg-inline--fa fa-edit fa-w-15 fa-2x"
																		role="img"
																		xmlns="http://www.w3.org/2000/svg"
																		viewBox="0 0 576 512"
																	>
																		<path
																			fill="currentColor"
																			d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"
																		></path>
																	</svg>
																</span>
															</span>
														</td>
													</tr>
												);
											})}
										</React.Fragment>
									))}
								</tbody>
							</Table>
						</Row>
						:
						<Row className='justify-content-center mt-2'>
							<Col className='col-auto'>
								<h4 id="no-case-schedule-message"></h4>
							</Col>
						</Row>
				}
			</Form >
			<Row style={{ position: 'absolute', bottom: '30px', align: 'centre' }}>
				<TablePagination
					pageCount={pageCount}
					handlePageClick={onPageClick}
					pageSize={pageSize}
					handlePageSizeChange={handlePageSize}
					isDisablePrevious={isPreviousDisabled}
					isDisableNext={isNextDisabled}
					previousClick={handlePreviousClick}
					nextClick={handleNextClick}
					pageIndex={pageNumber - 1}
				/>
			</Row>
		</>
	)
}