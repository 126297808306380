import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTableSearchBox1 from '../advance-table-1/AdvanceTableSearchBox1';
import AdvanceTableFooter1 from '../advance-table-1/AdvanceTableFooter1';
import { Col, Row } from 'react-bootstrap';
import AdvanceTable2 from './AdvanceTable2';
import useAdvanceTable from 'hooks/useAdvanceTable';
import React, { useCallback, useState } from 'react';

const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
const AdvanceTableComponent2 = ({
  data,
  columns,
  fetchList,
  dataCount,
  searchText,
  setSearchTextValue
}) => {
  const table = useAdvanceTable({
    data,
    columns,
    fetchList,
    dataCount,
    pageSize: 10,
    searchText,
    setSearchTextValue,
    // sortable: true,
    pagination: true
  });

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSizeState, setPageSizeState] = useState(10);
  const [pageLength, setPageLength] = useState(10);
  const canPreviousPage = pageIndex > 1;
  const canNextPage = pageIndex < Math.ceil(dataCount / pageLength);

  const nextPage = () => {
    if (canNextPage) {
      setPageIndex(pageIndex + 1);
      fetchList(pageIndex + 1, pageLength, searchText);
    }
  };

  const previousPage = () => {
    if (canPreviousPage) {
      setPageIndex(pageIndex - 1);
      fetchList(pageIndex - 1, pageLength, searchText);
    }
  };

  const handleSetPageSize = size => {
    console.log('size', size);
    setPageSizeState(Number(size));
    setPageLength(Number(size));
    setPageIndex(1); // Reset to the first page
    fetchList(1, Number(size), searchText);
  };

  const debouncedFetchList = useCallback(
    debounce((page, size, searchText) => {
      fetchList(page, size, searchText);
    }, 500), // 0.5-second debounce
    []
  );

  const handleSearchText = searchText => {
    setSearchTextValue(searchText);
    setPageIndex(1); // Reset to the first page
    debouncedFetchList(1, pageLength, searchText);
  };

  return (
    <>
    <AdvanceTableProvider {...table}>
      <Row className="flex-end-center mb-2 action-table">
        <Col xs="auto" sm={6} lg={4} className='search-text-column'>
          <AdvanceTableSearchBox1
            table
            handleSearchText={handleSearchText}
            searchText={searchText}
          />
        </Col>
      </Row>
      <div className="table-wrapper">
      <AdvanceTable2
        headerClassName="bg-light align-middle"
        rowClassName="align-middle white-space-nowrap"
        tableProps={{
          bordered: true,
          striped: true,
          className: 'fs-10 mb-0 overflow-hidden custom-table',
        }}
      />
         </div>
      <div className="mt-2 action-footer">
        <AdvanceTableFooter1
          fetchList={fetchList}
          rowCount={dataCount}
          pageSize={pageLength}
          pageIndex={pageIndex}
          setPageSize={handleSetPageSize}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          nextPage={nextPage}
          previousPage={previousPage}
          rowInfo
          navButtons
          rowsPerPageSelection
          className={"action-footer-child"}
        />
      </div>
    </AdvanceTableProvider>
    </>
  );
};

export default AdvanceTableComponent2;