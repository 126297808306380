import React, { useState, useEffect } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { formChangedAction } from 'actions';
import { caseTypeMasterDataDetailAction } from 'actions/CaseTypeMaster/caseTypeMasterAction';
import { toast } from 'react-toastify';
import { courtMasterListAPI } from 'actions/CourtMaster/courtMasterAction';
import { countryListAPI } from 'actions/CountryMaster/countryMasterAction';
import { stateListAPI } from 'actions/StateMaster/stateMasterAction';
import FalconComponentCard from 'components/common/FalconComponentCard';

const CaseTypeMasterDetail = () => {
  const dispatch = useDispatch();

  const resetCaseTypeMasterData = () => {
    dispatch(
      caseTypeMasterDataDetailAction({
        'caseType': '',
        'caseCode': '',
        'stateCode': '',
        'courtCode': '',
        'countryCode': '',
         'status': "Active"
      })
    );
  };

  useEffect(() => {
    getCountries();
  }, []);

  const caseTypeMasterReducer = useSelector(
    state => state.rootReducer.caseTypeMasterReducer
  );
  var caseTypeMasterData = caseTypeMasterReducer.caseTypeMasterDetail;

    if (
      !caseTypeMasterReducer.caseTypeMasterDetail ||
      caseTypeMasterReducer.caseTypeMasterDetail.length <= 0
    ) {
      resetCaseTypeMasterData();
    }

  const caseTypeMasterErrorReducer = useSelector(
    state => state.rootReducer.caseTypeMasterErrorReducer
  );
  const caseTypeMasterDetailsErr =
    caseTypeMasterErrorReducer.caseTypeMasterDetailsError;

  const formChangedReducer = useSelector(
    state => state.rootReducer.formChangedReducer
  );
  var formChangedData = formChangedReducer.formChanged;

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [courtMasterList, setCourtMasterList] = useState([]);
  const [perPage, setPerPage] = useState(15);
  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  useEffect(() => {
    if (
      caseTypeMasterData &&
      caseTypeMasterData.stateCode &&
      !$('#txtStateName').val()
    ) {
      getStates(caseTypeMasterData.countryCode);
    } else {
      setStateList([]);
    }
  }, [caseTypeMasterData.countryCode]);

  useEffect(() => {
    if (
      caseTypeMasterData &&
      caseTypeMasterData.courtCode &&
      !$('#txtCourtCode').val()
    ) {
      getCourtMasterList(caseTypeMasterData.stateCode);
    } else {
      setCourtMasterList([]);
    }
  }, [caseTypeMasterData.stateCode]);

  const getCourtMasterList = async selectedStateCode => {
    const data = {
      stateCode: selectedStateCode,
    };
    dispatch(
      courtMasterListAPI(
        data,
        res => {
          let courtMasterData = [];
          if (res.data.data.courtList.length > 0)
            res.data.data.courtList.forEach(courtMaster => {
              courtMasterData.push({
                key: courtMaster.courtName,
                value: courtMaster.courtCode
              });
            });
          setCourtMasterList(courtMasterData);
        },
        err => {
          setCourtMasterList([]);
        },
        headers
      )
    );
  };

  const getCountries = (page, size = perPage) => {
    const data = {
      pageNumber: page,
      pageSize: size,
      SearchText: '',
      isDropDown: true
    };
    dispatch(
      countryListAPI(
        data,
        res => {
          let countryData = [];
          if (res.data.data.countryList.length > 0)
            res.data.data.countryList.forEach(country => {
              countryData.push({
                key: country.countryName,
                value: country.countryCode
              });
            });
          setCountryList(countryData);
        },
        err => {
          toast.error(err?.data?.message || 'Error fetching countries', {
            theme: 'colored',
            autoClose: 10000
          });
          setCountryList([]);
        },
        headers
      )
    );
  };

  const getStates = async selectCountryCode => {
    const data = {
      countryCode: selectCountryCode,
      isDropDown: true,
      isList: false
    };
    dispatch(
      stateListAPI(
        data,
        res => {
          let stateData = [];
          if (res && res.length > 0) {
            res.forEach(state => {
              stateData.push({
                key: state.stateName,
                value: state.stateCode
              });
            });
            setStateList(stateData);
          } else {
            setStateList([]);
          }
        },
        err => {
          setStateList([]);
        }
      )
    );
  };

  const handleFieldChange = e => {
    dispatch(
      caseTypeMasterDataDetailAction({
        ...caseTypeMasterData,
        [e.target.name]: e.target.value
      })
    );
    if (e.target.name == 'countryCode') {
      dispatch(
        caseTypeMasterDataDetailAction({
          ...caseTypeMasterData,
          countryCode: e.target.value,
          stateCode: null
        })
      );
      setStateList([]);
      e.target.value && getStates(e.target.value);
    } else if (e.target.name == 'stateCode') {
      dispatch(
        caseTypeMasterDataDetailAction({
          ...caseTypeMasterData,
          stateCode: e.target.value,
          courtCode: null
        })
      );
      setCourtMasterList([]);
      e.target.value && getCourtMasterList(e.target.value);
    }
    if (caseTypeMasterData.encryptedCaseCode) {
      dispatch(
        formChangedAction({
          ...formChangedData,
          caseTypeMasterUpdate: true
        })
      );
    } else {
      dispatch(
        formChangedAction({
          ...formChangedData,
          caseTypeMasterAdd: true
        })
      );
    }
  };

  return (
    <>
      {caseTypeMasterData && (
        <FalconComponentCard className="no-pb mb-1 comman-card">
          <FalconComponentCard.Body language="jsx">
            <Form className="details-form" id="addCaseTypeMasterDetailsForm">
              <Row>
                <Col md="4" sm="6" xs="12">
                         <Row className="">
                  <Form.Group
                    as={Row}
                    className="mb-1"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4">
                      Case Code
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        id="txtCaseCode"
                        name="caseCode"
                        maxLength={5}
                        placeholder="Case Code"
                        value={caseTypeMasterData.caseCode}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-1"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4">
                      Case Type<span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        id="txtCaseType"
                        maxLength={50}
                        name="caseType"
                        placeholder="Case Type"
                        value={caseTypeMasterData.caseType}
                        onChange={e => handleFieldChange(e)}
                      />
                      {Object.keys(caseTypeMasterDetailsErr.caseTypeErr).map(
                        key => {
                          return (
                            <span className="error-message">
                              {caseTypeMasterDetailsErr.caseTypeErr[key]}
                            </span>
                          );
                        }
                      )}
                    </Col>
                  </Form.Group>
                  </Row>
                  <Row className="">
                  <Form.Group
                    as={Row}
                    className="mb-1"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4">
                      Court Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Select
                        id="txtCourtCode"
                        name="courtCode"
                        value={caseTypeMasterData.courtCode}
                        onChange={handleFieldChange}
                      >
                        <option value="">Select</option>
                        {courtMasterList.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.key}
                          </option>
                        ))}
                      </Form.Select>
                      {Object.keys(caseTypeMasterDetailsErr.courtNameErr).map(
                        key => {
                          return (
                            <span className="error-message">
                              {caseTypeMasterDetailsErr.courtNameErr[key]}
                            </span>
                          );
                        }
                      )}
                    </Col>
                  </Form.Group>
                  </Row>
                </Col>
             
              
                <Col md="4" sm="6" xs="12">
                <Row className="">
                  <Form.Group
                    as={Row}
                    className="mb-1"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4">
                      Country Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Select
                        id="txtCountryName"
                        name="countryCode"
                        value={caseTypeMasterData.countryCode}
                        onChange={handleFieldChange}
                      >
                        <option value="">Select</option>
                        {countryList.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.key}
                          </option>
                        ))}
                      </Form.Select>
                      {Object.keys(caseTypeMasterDetailsErr.countryNameErr).map(
                        key => {
                          return (
                            <span className="error-message">
                              {caseTypeMasterDetailsErr.countryNameErr[key]}
                            </span>
                          );
                        }
                      )}
                    </Col>
                  </Form.Group>
                  </Row>
                  <Row className="">
                  <Form.Group
                    as={Row}
                    className="mb-1"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4">
                      State Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Select
                        id="txtStateName"
                        name="stateCode"
                        value={caseTypeMasterData.stateCode}
                        onChange={handleFieldChange}
                      >
                        <option value="">Select</option>
                        {stateList.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.key}
                          </option>
                        ))}
                      </Form.Select>

                      {Object.keys(caseTypeMasterDetailsErr.stateNameErr).map(
                        key => {
                          return (
                            <span className="error-message">
                              {caseTypeMasterDetailsErr.stateNameErr[key]}
                            </span>
                          );
                        }
                      )}
                    </Col>
                  </Form.Group>
                  </Row>
                  <Row className="">
                  <Form.Group
                    as={Row}
                    className="mb-1"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4">
                      Status
                    </Form.Label>
                    <Col sm="8">
                      <Form.Select
                        id="txtStatus"
                        name="status"
                        value={caseTypeMasterData.status ? caseTypeMasterData.status : "Active"}
                        onChange={handleFieldChange}
                      >
                        <option value="Active" selected>Active</option>
                        <option value="Suspended">Suspended</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  </Row>
                </Col>
                
              </Row>
            </Form>
          </FalconComponentCard.Body>
        </FalconComponentCard>
      )}
    </>
  );
};

export default CaseTypeMasterDetail;