import { clientCaseDetailListAPI } from 'actions/AdvocateClientCase/AdvocateClientCaseAction';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

const ClientCaseDetailModal = ({
  show,
  hide,
  selectedClientCode,
  selectedClientName
}) => {
  const dispatch = useDispatch();
  const [clientCaseDetailList, setClientCaseDetailList] = useState([]);

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  useEffect(() => {
    if (show && selectedClientCode) {
      getClientCaseDetailList(selectedClientCode);
    }
  }, [show, selectedClientCode]);

  const getClientCaseDetailList = () => {
    const data = {
      encryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode'),
      clientCode: selectedClientCode
    };
    dispatch(
      clientCaseDetailListAPI(
        data,
        response => {
          setClientCaseDetailList(response.data.data.clientCaseDetailList);
        },
        err => {
          setClientCaseDetailList([]);
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  const getTextColorClass = nextDate => {
    const currentDate = moment().format('DD-MM-YYYY');
    const twoDaysFromNow = moment().add(2, 'days').format('DD-MM-YYYY');

    if (!nextDate) return '';

    if (nextDate === twoDaysFromNow) {
      return 'text-amber';
    } else if (nextDate < currentDate) {
      return 'text-red';
    } else if (nextDate > currentDate) {
      return 'text-blue';
    }
    return '';
  };

  return (
    <>
      <Modal show={show} onHide={hide} centered className="my-modal">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Case Detail
          </Modal.Title>
        </Modal.Header>
        {/* <Modal.Body className="modelClientList"> */}
        <Modal.Body>
          <h4 className="text-center client-list-popup-card-heading">
            Client Name -{' '}
            <span className="table-title">{selectedClientName || ''}</span>
          </h4>
          <div className="table-container">
          <Table
            bordered
            // responsive
            id="TableList"
            className="no-pb text-nowrap tab-page-table custom-table client-list-pop-up"
          >
            <thead className="custom-bg-200">
              <tr>
                <th>S.No.</th>
                <th>Case No.</th>
                <th>Previous Date</th>
                <th>Current Date</th>
                <th>File Location(F-R-A-R)</th>
              </tr>
            </thead>
            <tbody>
              {clientCaseDetailList.map((item, index) => (
                <tr key={index}>
                  <td className={getTextColorClass(item.nextDate)}>
                    {index + 1}
                  </td>
                  <td className={`${getTextColorClass(item.nextDate)} td-truncate-text`} title={item.caseNo}>
                    {item.caseNo}
                  </td>
                  <td className={getTextColorClass(item.nextDate)}>
                    {item.previousDate}
                  </td>
                  <td className={getTextColorClass(item.nextDate)}>
                    {item.nextDate}
                  </td>
                  <td className={`${getTextColorClass(item.nextDate)} td-truncate-text`} title={item.fileLocation}>
                    {item.fileLocation}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ClientCaseDetailModal;
