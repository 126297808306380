import { Card, Col, Row, Spinner } from 'react-bootstrap';
import FalconComponentCard from '../FalconComponentCard';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { clientListAPI } from 'actions/ClientRegistration/ClientRegistrationAction';
import AdvanceTableComponent2 from '../advance-table-2/AdvanceTableComponent2';
import { tooltip } from 'leaflet';

const columns = [
  {
    accessorKey: 'clientName',
    header: 'Client Name',
    meta: {
      headerProps: { className: 'text-dark' },
      cellProps: { className: 'client-name-column' } },
    cell: ({ getValue }) => (
      <span title={getValue()} className="truncate-text">
        {getValue()}
      </span>
    )
  },
  {
    accessorKey: 'mobileNo',
    header: 'Mobile No.',
    meta: { headerProps: { className: 'text-dark' } }
  },
  {
    accessorKey: 'emailId',
    header: 'Email',
    meta: {
      headerProps: { className: 'text-dark email-clientcard-column' },
      cellProps: { className: 'email-id-column' }
    }
  },
  {
    accessorKey: 'totalNoOfCase',
    header: 'No. Of Case',
    meta: {
      headerProps: { className: 'no-of-case-column noofcase-clientcard-column' },
      cellProps: { className: 'no-of-case-column' }
    }
  }
];

const ClientListCard = () => {
  const dispatch = useDispatch();
  const [clientList, setClientList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState('');

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  useEffect(() => {
    getClientList(1, pageSize);
  }, []);

  const getClientList = (page, size = pageSize, searchText) => {
    const data = {
      pageNumber: page,
      pageSize: size,
      SearchText: searchText ? searchText : '',
      EncryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode')
    };
    setIsLoading(true);
    dispatch(
      clientListAPI(
        data,
        response => {
          setIsLoading(false);
          setClientList(response.data.data.clientList);
          setTotalCount(response.data.data.totalCount);
          $(".client-dashboard-card table thead").removeClass("action-head");
        },
        err => {
          setIsLoading(false);
          setClientList([]);
          console.log(err?.data.message);
          $(".client-dashboard-card table thead").addClass("action-head");
        },
        headers
      )
    );
  };

  return (
    <>
      <Card className="card-dashbord-bg  dashboard-bolow-card client-card-header  border-0">
        <Row className="align-items-center m-0 p-0">
          <Col md={11} className="p-0">
            <Card.Header className="  fw-bolder text-center ms-4 case-schedule-card-dash p-1 text-white">
              My Client List
            </Card.Header>
          </Col>
        </Row>
      </Card>
      <FalconComponentCard className=" client-dashboard-card">
        {isLoading ? (
          <Spinner
            className="position-absolute start-50 loader-color "
            animation="border"
          />
        ) : null}
        <FalconComponentCard.Body className="bg-white mb-2 mt-2 remove-border-client client-card-body action-padding ">
          <AdvanceTableComponent2
            data={clientList}
            columns={columns}
            pageSize={pageSize}
            setPageSize={0}
            searchText={searchText}
            setSearchTextValue={value => setSearchText(value)}
            fetchList={getClientList}
            dataCount={totalCount}
               className="client-card-body"
          />
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default ClientListCard;
