import { updateActionDetailStatusAPI } from 'actions/ActionDetails/actionDetailsAction';
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const UpdateActionDetailStatusModal = ({ show, onCancel, data }) => {
  const dispatch = useDispatch();

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  const updateActionDetailReminderStatus = actionId => {
    const data = {
      actionDetailId: actionId,
      reminderStatus: 'C',
      modifiedBy: localStorage.getItem('LoginUserName')
    };
    dispatch(
      updateActionDetailStatusAPI(
        data,
        response => {
          toast.success(` ${response.data.message}`, {
            theme: 'colored',
            autoClose: 10000
          });
          onCancel();
          setTimeout(() => {
            window.location.reload();
          }, [500]);
        },
        err => {
          toast.error(err?.data.message, {
            theme: 'colored',
            autoClose: 10000
          });
        },
        headers
      )
    );
  };

  const updateStatus = data => {
    updateActionDetailReminderStatus(data);
  };

  return (
    <>
      <Modal show={show} onHide={onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to close this Action?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => updateStatus(data)}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateActionDetailStatusModal;