import { caseNextDateListAPI } from 'actions/AdvocateClientCase/AdvocateClientCaseAction';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import FalconComponentCard from '../FalconComponentCard';
import AdvanceTableComponent2 from '../advance-table-2/AdvanceTableComponent2';

const columns = [
  {
    accessorKey: 'clientName',
    header: 'Client Name',
    meta: {
      headerProps: { className: 'text-dark' },
      cellProps: { className: 'client-name-column' }
    },
    cell: ({ getValue }) => (
      <span title={getValue()} className="truncate-text">
        {getValue()}
      </span>
    )
  },
  {
    accessorKey: 'caseDetail',
    header: 'Case No. / Case Title',
    meta: {
      headerProps: { className: 'text-dark' },
      cellProps: { className: 'client-detail-column' }
    },
    cell: ({ getValue }) => (
      <span title={getValue()} className="truncate-text">
        {getValue()}
      </span>
    )
  },
  {
    accessorKey: 'nextDate',
    header: 'Last Date',
    meta: { headerProps: { className: 'text-dark next-date-column' } }
  },
  {
    accessorKey: 'status',
    header: 'Status',
    meta: {
      headerProps: { className: 'text-dark' },
      cellProps: { className: 'status-casenextCard-column' }
    }
  },
  {
    accessorKey: 'action',
    header: 'Action',
    meta: {
      headerProps: { className: 'text-dark' }
    }
  }
];
const CaseNextDateList = () => {
  const dispatch = useDispatch();
  const [caseNextDateList, setCaseNextDateList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState('');

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  useEffect(() => {
    getCaseNextDateList(1, pageSize);
  }, []);

  const getCaseNextDateList = async (page, size = pageSize, searchText) => {
    const data = {
      pageNumber: page,
      pageSize: size,
      SearchText: searchText ? searchText : '',
      EncryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode')
    };
    setIsLoading(true);
    await dispatch(
      caseNextDateListAPI(
        data,
        response => {
          setIsLoading(false);
          setCaseNextDateList(response.data.data.caseNextDateList);
          setTotalCount(response.data.data.totalCount);
          $(".next-case-list-card table thead").removeClass("action-head");
        },
        err => {
          setIsLoading(false);
          $(".next-case-list-card table thead").addClass("action-head");
          setCaseNextDateList([]);
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  return (
    <>
      <Card className="card-dashbord-bg dashboard-bolow-card next-case-card-header border-0">
        <Row className="align-items-center m-0 p-0">
          <Col md={9} className="p-0">
            <Card.Header className="fw-bolder text-center ms-8 case-schedule-card-dash p-1 text-white">
              Cases Pending for Next Date Update
            </Card.Header>
          </Col>
        </Row>
      </Card>
      <FalconComponentCard className="next-case-list-card">
        {isLoading ? (
          <Spinner
            className="position-absolute start-50 loader-color "
            animation="border"
          />
        ) : null}
        <FalconComponentCard.Body className="bg-white mb-2 mt-2 remove-border-client action-padding next-case-list-card-body ">
          <AdvanceTableComponent2
            data={caseNextDateList}
            columns={columns}
            pageSize={pageSize}
            setPageSize={0}
            searchText={searchText}
            setSearchTextValue={value => setSearchText(value)}
            fetchList={getCaseNextDateList}
            dataCount={totalCount}
          />
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default CaseNextDateList;